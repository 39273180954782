import React, { useState, useEffect } from 'react';
import { StoreContext } from '../Hooks/store';

import { Button, Container, Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
//import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
//import CurrentDate from './CurrentDate';
import CurrentDate from '../Utils/CurrentDate';
import CurrentDateV2 from '../Utils/CurrentDateV2';


import firebase from '../firebase';
import sqlitedb from '../sqlitedb';

import { ReactComponent as BackArrow } from './back_arrow.svg';
import { ReactComponent as ForwardArrow } from './fwd_arrow.svg';

import { notify } from "../Notifications";

//import styled from 'styled-components';

import { StatusIcon } from '../Shared/StatusIcon'
import { localTime } from '../Utils/localTime';
import { prettyTime } from '../Utils/prettyTime';

// import { gql } from "apollo-boost";
// import { useMutation } from "@apollo/react-hooks";
import { hashCode } from '../Utils/hashCode';

import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';

// export const SvgIcon = styled.svg`
//   transition: fill 0.25s;
//   width: 26px;
//   height: 26px;

//   ${Link}:hover & { // <-- This is what we can do now.
//     fill: rebeccapurple;
//   }
// `;
// TODO getlocation(), logPosition, addPosition

//https://codesandbox.io/s/v8qy4w1j77 // for NestedGridDemo
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        backgroundColor: '#b3b3b3',
        color: '#666',
    },
    iconwidth: {
        minWidth: '35px !important',
    },
    menulabelwidth: {
        minWidth: '90px !important',
    },
    menuwidth: {
        width: 'inherit',
        marginLeft: '-12px',
        minWidth: '99px',
    },
    tctitle: { fontFamily: 'Montserrat', backgroundColor: '#00416e', color: '#fff', textAlign: 'center' },

    menuDiv: { marginBottom: '6px', marginLeft: '-6px' },
    timecardMenu: { fontSize: '12pt', verticalAlign: 'middle' },
    tcstatusicon: { float: 'left', verticalAlign: 'text-top' },

    tcstatus: { textAlign: 'center', padding: '10px', fontSize: '16pt' },
    tcstatusL: { fontFamily: 'Montserrat', fontWeight: '700', fontSize: '14pt' },
    tcstatusR: { fontFamily: 'Montserrat', fontWeight: '500', fontSize: '16pt' },

    tctime: { fontFamily: 'Montserrat', fontWeight: '400', fontSize: '24pt', textAlign: 'center' },
    tctimeC: { textAlign: 'center' },
    tctimeL: { textAlign: 'left', marginRight: '10px' },
    tctimeR: {
        textAlign: 'right', marginLeft: '10px',
        '&:disabled': {
            color: 'red'
        },
    },
    tcarrow: {
        fill: '#000',
        '&:hover': {
            fill: '#b3b3b3'
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    b1: {
        backgroundColor: '#b3b3b3',
        minWidth: '270px',
        marginLeft: '-5px',
    },
    tcentries: {
        backgroundColor: '#fff',
        margin: '20px -12px -8px -12px'
    },
    grayborder: {
        borderBottom: "1px solid #b3b3b3",
        fontFamily: 'Montserrat',
        color: '#666'
    },
    tcleft: {
        fontSize: '12pt',
        fontWeight: '600',
        textAlign: 'right',
        margin: '0 5px 0 15px'
    },
    tcright: {
        fontSize: '12pt',
        fontWeight: '500',
        textAlign: 'left',
        marginLeft: '5px',
    },
    daysplitter: {
        fontFamily: 'Montserrat',
        fontSize: '14pt',
        fontWeight: '700',
        textAlign: 'left',
        marginLeft: '5px',
        color: '#000'
    },
}));

const ClockinButton = withStyles(() => ({
    root: {
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: '12pt',
        lineHeight: '1.2',
        color: '#666666',
        border: 'solid 1px',
        backgroundColor: '#FFF',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#8CC63F',
            color: '#FFF'
        },
        '&:disabled': {
            backgroundColor: '#fff',
            color: '#b3b3b3'
        },
        minWidth: '140px',
    },
}))(Button);


const BreakButton = withStyles(() => ({
    root: {
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: '12pt',
        lineHeight: '1.2',
        color: '#666',
        border: 'solid 1px',
        backgroundColor: '#fff',
        textTransform: 'none',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#FBB03B'
        },
        '&:disabled': {
            backgroundColor: '#fff',
            color: '#b3b3b3',
        },
        minWidth: '140px',
    },
}))(Button);


// const CREATE_TIMED_ACTIVITY = gql`
//     mutation CreateTimedActivity($n: String!, $si: String, $c: Float!, $s: Float, $e: Float, $t: Float, $tz: String, $tzo: Int, $m: Float) {
//         CreateTimedActivity(name: $n, sessionId: $si, createTs: $c, startUtc: $s, endUtc: $e, totalSecs: $t, tmZone: $tz, tmZoneOffset: $tzo, modTs: $m) {
//             _id
//             name
//             totalSecs
//         }
//     }
// `;


export default function TimeCard(props) {

    const {
        timecard,
        timecardsessionid,
    } = React.useContext(StoreContext);
    const [statuscolorTimecard, setStatuscolorTimecard] = timecard;
    const [tcsessionid, setTcsessionid] = timecardsessionid;

    const classes = useStyles();

    const [menucolor] = useState(props.rendercolor)

    const [open, setOpen] = useState(false); //stay
    const [clockedin, setClockedin] = useState(false);
    //const [statuscolor, setStatuscolor] = useState('#231f20');
    const [showingHistory, setShowingHistory] = useState(false);
    const [workbreak, setWorkbreak] = useState('Break');
    const [clockbtnlabel, setClockbtnlabel] = useState('Clock In');
    const [status, setStatus] = useState('Clocked Out');
    const [timecards, setTimecards] = useState([]);
    const [clockinstart, setClockinstart] = useState(0);
    const [breakstart, setBreakstart] = useState(0);
    const [tctime, setTctime] = useState(0); //stay
    const [tcdate, setTcdate] = useState(new Date().toLocaleDateString()); //stay
    const [latlon, setLatlon] = useState({
        lat: 0,
        lon: 0,
        now: 0
    });
    const [fwdnavdisabled, setFwdnavdisabled] = useState(true);
    
    const { trackInteraction } = useUserInteractionTracker();
    //let timecardicon = menucolor === 'primary' ? <ScheduleOutlinedIcon color="primary" /> : <ScheduleOutlinedIcon style={{ color: menucolor }} />

    useEffect(() => {
        trackInteraction('page_view', { pageName: 'Timecard' });
        
        notify('Loading....')
        //testHash()
        getTimeCards(new Date().setHours(24, 0, 0, 0))
        handleLocation()
    },
        [] //https://www.robinwieruch.de/react-hooks/ infinite loop fix
    );



    // useEffect(() => {
    //     const fetchData = async () => {
    //       await SQLiteUtils.initializeDatabase();
    //       const result = SQLiteUtils.executeQuery('SELECT * FROM users');
    //       console.log('sqlite tet users:');
    //       console.log(result);
    //     };

    //     fetchData();
    //   }, []);

    function handleLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(saveCurrentLocation);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    function saveCurrentLocation(position) {

        // https://googleapis.dev/nodejs/firestore/latest/GeoPoint.html

        const location_payload = `{
        "lat": ${position.coords.latitude}, 
        "lon": ${position.coords.longitude},
        "now": ${Date.now()}
    }`;

        const locObj = JSON.parse(location_payload);
        setLatlon(locObj);
    }


    async function addTimeCard(tcData) {
        trackInteraction('form_submit', {buttonText: 'Save.Timecard' });
        // (async () => {
        //     console.log('call turso');
        //     console.log(await get_turso_test())
        //   })()

        //get_turso_test().catch(console.log);
        // Good reference code below
        // await firebase.get_turso_test().then(x => { 
        //     console.log('get_turso_test 2');
        //     //console.log(`turso result = ${x.rows}`); 
        //     Object.entries(x).map(([, v]) => {
        //         console.log(v.ID, v.name);
        //     });
        // });


        try {
            //await firebase.addTimeCardEntry(tcData)
            await sqlitedb.addTimeCardEntry(tcData);
        } catch (error) {
            console.log('Clock in/out addTimeCard reported error below...');
            console.log(error)
        }
    }

    function get_time_diff(timepast, timenow = new Date().getTime()) {
        // the following is to handle cases where the times are on the opposite side of
        // midnight e.g. when you want to get the difference between 9:00 PM and 5:00 AM
        if (timenow < timepast) {
            timenow.setDate(timenow.getDate() + 1);
        }

        return (timenow - timepast) / 1000;
    }

    async function getTimeCards(tcData) {
        try {
            const testdt = new Date(tcData).setHours(24, 0, 0, 0);
            if (testdt > new Date().toLocaleDateString()) {
                setFwdnavdisabled(false);
                return;
            }

            //return await firebase.getTimeCardEntriesForDate(tcData).then(x => {
            return await sqlitedb.getTimeCardEntriesForLast5days(tcData).then(x => {
                const temp = [];
                let latest_mod_eventname = '';
                let latest_saved = 0;
                let latest_mod_utc_end = 0;
                let latest_no_clockout = 0;
                let latest_saved_day = 0;
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                
                if (!x) {
                    return;
                }
                Object.entries(x).map(([, v]) => {

                    if (v.utc_saved > latest_saved) { // prep to calculate newest event
                        latest_saved = v.utc_saved;
                        latest_mod_eventname = v.event_name;
                        latest_mod_utc_end = v.utc_end;
                    }

                    //let current_seen_date = new Date(v.utc_saved).toLocaleDateString();
                    let current_seen_day = new Date(v.utc_saved).setHours(24, 0, 0, 0);
                    //console.log(current_seen_day);

                    if (current_seen_day > latest_saved_day) {
                        latest_saved_day = current_seen_day;

                        // add a date separator for the UI
                        let SeparatorObj = {
                            utc_start: '',
                            event_name: new Date(v.utc_saved).toLocaleDateString('en-us', options)
                        };
                        temp.push(SeparatorObj);
                    }

                    // https://youtu.be/RZ5wKYbOM_I
                    let newObj = {
                        utc_start: localTime(v.utc_saved),
                        event_name: v.event_duration_ms < 1 ? v.event_name : `${v.event_name} (${prettyTime(v.event_duration_ms)})`
                    };
                    temp.push(newObj);


                    //console.log(v.event_name, v.utc_saved)
                    if (v.event_name === 'Clocked In' && v.utc_end === 0) { // calculate newest event
                        //console.log('Clocked In but not out...', v.utc_saved)
                        latest_no_clockout = v.utc_saved;
                    }


                })

                //setTimecards([...timecards, ...temp])
                setTimecards(temp)


                //console.log(`latest_mod_eventname=${latest_mod_eventname},latest_saved=${latest_saved},latest_mod_utc_end=${latest_mod_utc_end},latest_no_clockout=${latest_no_clockout}`)
                if (latest_mod_utc_end < 1 || latest_no_clockout > 0) { // if the most recently-saved event has not completed or we have a clock-in that never clockedout
                    //console.log(`AGAIN... latest_mod_eventname=${latest_mod_eventname},latest_saved=${latest_saved},latest_mod_utc_end=${latest_mod_utc_end},latest_no_clockout=${latest_no_clockout}`)
                    switch (latest_mod_eventname) {
                        case "On Break":
                            setClockedin(true)
                            setWorkbreak('End Break')
                            setClockbtnlabel('Clock Out')
                            setStatus('On Break')
                            setBreakstart(latest_saved);
                            break;
                        case "Clocked In":
                            setClockedin(true)
                            setStatus('Clocked In')
                            setClockbtnlabel('Clock Out')
                            setClockinstart(latest_saved);
                            setWorkbreak('Break')
                            break;
                        case "End Break":
                            setClockedin(true)
                            setStatus('Clocked In')
                            setClockbtnlabel('Clock Out')
                            setClockinstart(latest_no_clockout);
                            setWorkbreak('Break')
                            break;
                        default:
                            setClockedin(false)
                            setClockbtnlabel('Clock In')
                            setStatus('Clocked Out')
                            break;
                    }
                }
            })
        } catch (error) {
            console.log(error)
            notify(error.message)
        }
    }

    const TimeCardItems = React.memo(() => (
        <div className={classes.tcentries}>
            {timecards.map((tc) => {
                const trackId = `list-item-label-${tc.utc_saved}`;
                return (
                    <div className={classes.grayborder}>
                        <span className={classes.tcleft}>{`${tc.utc_start}`}</span><span className={(tc.utc_start === '' ? classes.daysplitter : classes.tcright)} id={trackId}>{`${tc.event_name}`}</span>
                    </div>
                );
            })}
        </div>
    ));

    function makeTimeCardData(startTime, eventName, eventDuration = 0, endTime = 0) {
        const yyyy_mm_dd = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        const tc_payload = `{
            "event_name": "${eventName}", 
            "event_duration_ms": ${eventDuration},
            "event_date": "${yyyy_mm_dd}",
            "utc_start": ${startTime},
            "utc_end": ${endTime},
            "latitude": ${latlon.lat},
            "longitude": ${latlon.lon}
        }`;
        return tc_payload;
    }

    function handleClockin() {
        const event_name = !clockedin ? 'Clocked In' : 'Clocked Out';
        const rightnow = Date.now();

        console.log(event_name)
        console.log(rightnow)
        if (!clockedin) {
            trackInteraction('button_click', { buttonText: 'Timecard.Clockin' });
            setStatuscolorTimecard('#8CC63F')
            setClockedin(true)
            setClockinstart(rightnow);
            setStatus('Clocked In')
            setClockbtnlabel('Clock Out')
            setTcsessionid(hashCode(firebase.getCurrentUserEmail()) + '_' + Date.now())
            addTimeCard(JSON.parse(makeTimeCardData(rightnow, event_name)));



        } else {
            trackInteraction('button_click', { buttonText: 'Timecard.Clockout' });
            setStatuscolorTimecard('#231f20')
            setClockedin(false)
            setStatus('Clocked Out')
            setClockbtnlabel('Clock In')

            checkBreakstatus(event_name)

            const duration = (clockinstart > 0) ? get_time_diff(clockinstart, rightnow) : 0;
            addTimeCard(JSON.parse(makeTimeCardData(rightnow, event_name, duration, rightnow)));


            setTcsessionid('');

            setClockinstart(0); //reset lunch start clock            
        }

        refreshUi(rightnow, event_name)
        notify(`You are ${event_name}`)
        handleOnClose()
    }

    function refreshUi(rightnow, event_name) {
        const temp = [];
        let newObj = {
            utc_start: localTime(rightnow),
            event_name: event_name
        };
        temp.push(newObj);

        setTimecards([...timecards, ...temp])
    }

    function checkBreakstatus(msg) {
        if (workbreak !== 'Break') {
            notify(`${msg}. Force end break!`)
            endBreak()
            setWorkbreak('Break')
        }
    }

    function handleBreak() {
        if (workbreak === 'Break') {
            trackInteraction('button_click', { buttonText: 'Timecard.Break' });
            setStatuscolorTimecard('#FBB03B')
            const msg = 'Going on break!'
            notify(msg)
            setWorkbreak('End Break')
            setStatus('On Break')

            const event_name = 'On Break'
            const rightnow = Date.now();
            setBreakstart(rightnow);
            addTimeCard(JSON.parse(makeTimeCardData(rightnow, event_name)));
            refreshUi(rightnow, event_name)

        } else {
            trackInteraction('button_click', { buttonText: 'Timecard.BreakEnd' });
            notify('Back from break!')
            setWorkbreak('Break')

            endBreak();

            const rightnow = Date.now();

            if (clockedin) {
                setStatus('Clocked In')
                setStatuscolorTimecard('#8CC63F')
            }
        }

        handleOnClose()
    }

    function endBreak() {
        const rightnow = Date.now();
        if (breakstart > 0) {
            const duration = get_time_diff(breakstart, rightnow)
            addTimeCard(JSON.parse(makeTimeCardData(breakstart, 'End Break', duration, rightnow)));
        } else {
            addTimeCard(JSON.parse(makeTimeCardData(-1, 'End Break', -1, rightnow)));
        }
        setBreakstart(0); //reset break start clock
    }

    function handleOnClose() {
        setOpen(false)
        trackInteraction('button_click', { buttonText: 'Timecard.Close' });
    }

    function handleOnOpen() {
        setOpen(true)
        trackInteraction('button_click', { buttonText: 'Timecard.Open' });
    }

    function oneDayBack() {
        const current_ts = tctime === 0 ? Date.now() : tctime;
        const oneday = 1 * 60 * 60 * 24 * 1000;
        const onedayback = current_ts - oneday;
        const onedayback_ts = new Date(onedayback).getTime();
        const onedayback_dt = new Date(onedayback).toLocaleDateString();

        if (onedayback_dt < new Date().toLocaleDateString()) {
            setShowingHistory(true);
        }

        setTcdate(onedayback_dt);
        setTctime(onedayback_ts);
        setTimecards([]) //reset

        notify(`Loading data for ${onedayback_dt}....`)
        getTimeCards(onedayback_ts);
    }

    function oneDayForward() {
        const current_ts = tctime === 0 ? Date.now() : tctime;
        const oneday = 1 * 60 * 60 * 24 * 1000;
        const onedayfwd = current_ts + oneday;
        const onedayfwd_ts = new Date(onedayfwd).getTime();
        const onedayfwd_dt = new Date(onedayfwd).toLocaleDateString();

        if (onedayfwd_dt === new Date().toLocaleDateString()) {
            setShowingHistory(false);
        }

        if (onedayfwd_dt > new Date().toLocaleDateString()) {
            setFwdnavdisabled(true)
            return;
        }

        setTcdate(onedayfwd_dt);
        setTctime(onedayfwd_ts);
        setTimecards([]) //reset

        notify(`Loading data for ${onedayfwd_dt}....`)
        getTimeCards(onedayfwd_ts);

    }

    return <Container>
        <div className={classes.menuDiv} onClick={handleOnOpen}>
            <StatusIcon className={classes.tcstatusicon} cls1={statuscolorTimecard} />
            <span className={classes.timecardMenu}>&nbsp;&nbsp;Time Card</span>
        </div>

        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle className={classes.tctitle}><CurrentDateV2 /></DialogTitle>
            <DialogContent className={classes.b1}>
                <div className={classes.tctime}>
                    <span className={classes.tctimeL}><BackArrow onClick={() => oneDayBack()} width={30} height={30} className={classes.tcarrow} /></span>
                    <span className={classes.tctimeC}>{tcdate}</span>
                    <span disabled={fwdnavdisabled} className={classes.tctimeR}><ForwardArrow disabled={fwdnavdisabled} onClick={() => oneDayForward()} width={30} height={30} className={classes.tcarrow} /></span>
                </div>
                <div className={classes.tcstatus}><StatusIcon className={classes.tcstatusicon} cls1={statuscolorTimecard} /><span className={classes.tcstatusL}>STATUS &nbsp;</span><span className={classes.tcstatusR}>{status}</span></div>
                <div className={classes.b1}>
                    <Grid id="top-row" container spacing={2} >
                        <Grid item xs={6}>
                            <ClockinButton width="100%" disabled={showingHistory} onClick={handleClockin} disableElevation>
                                <span>{clockbtnlabel}</span>
                            </ClockinButton>
                        </Grid>
                        <Grid item xs={6}>
                            <BreakButton width="100%" disabled={!clockedin || showingHistory} onClick={handleBreak} variant="contained" className={classes.margin}>
                                <span>{workbreak}</span>
                            </BreakButton>
                        </Grid>
                    </Grid>

                </div>
                <div className={classes.tcentries}>
                    <TimeCardItems />
                </div>
            </DialogContent>

        </Dialog>
    </Container>
}