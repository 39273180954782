import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { StoreContext } from '../Hooks/store';
import { PopupAddress, PopupBody } from './LocationTabs.styled';

import LocationTabs from './LocationTabs';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: 'Montserrat',
    backgroundColor: '#00416E',
    color: '#fff',
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {/* {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function PaperComponent(props) {
  return (
    <Draggable handle="#dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function CustomizedDialogs() {
  const { mapdialog, mapdialogopen } = React.useContext(StoreContext);
  const [dialogContent, setDialogContent] = mapdialog;
  const [dialogOpen, setDialogOpen] = mapdialogopen;

  const handleClose = () => {
    setDialogContent({}); // clear contents
    setDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={dialogOpen}
        PaperComponent={PaperComponent}
        aria-labelledby="dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }}
          id="dialog-title"
          onClose={handleClose}>
          {dialogContent.name}
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom>
            {dialogContent.description}
          </Typography> */}


          <PopupBody>
            <PopupAddress>
              {dialogContent.address}
            </PopupAddress>
            {/* <FullWidthTabs overview={dialogContent.description} /> */}
            <LocationTabs overview={dialogContent.description} />
          </PopupBody>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}