import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

//import {useFoo} from '../Hooks/useFoo';

const DialogTitle = styled.span`
  font-family: Montserrat;
  font-size: 1rem;font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`

const CurrentDate = (props) => {
  //const [time, setTime] = useState(new Date().toLocaleTimeString());

  const options = props.options || {  weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  //const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const [dt, setDt] = useState(new Date().toLocaleDateString('en-US', options));

  useEffect(() => {
    const timeout = setTimeout(() => {
      //const date = new Date()
      //setTime(date.toLocaleTimeString());
      //const prnDt = new Date().toLocaleTimeString('en-us', options);
      const prnDt = new Date().toLocaleDateString('en-US', options);
      // better solution here without using new Date() ???
      //https://stackoverflow.com/questions/4822852/how-to-get-the-day-of-week-and-the-month-of-the-year
      setDt(prnDt);
    }, 1000 * 60 * 60); // every hour
    return () => {
      clearTimeout(timeout);
    }
  }, [dt]);

  return (
      <DialogTitle>{dt}</DialogTitle>
  )
}

export default CurrentDate;