import styled from 'styled-components';

export const PopupDiv = styled.div`
    //font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 10pt;
    color: #666673;
    background-color: #fff;
`

export const PopupBody = styled(PopupDiv)`
    overflow-y: scroll;
    margin: -20px -17px 0 -17px; 
    padding: 0 1px 0 2px;
`

export const PopupAddress = styled(PopupDiv)`
    font-weight: 500;
    margin: 5px;
    font-size: 16pt;
`