import LZString from 'lz-string';

export function compressData(data) {
  const jsonString = JSON.stringify(data);
  const compressedData = LZString.compressToBase64(jsonString);
  // console.log("Compressed data length:", compressedData.length);
  return compressedData;
}

export function decompressData(compressedData) {
  try {
    const decompressedData = LZString.decompressFromBase64(compressedData);
    const decodedData = JSON.parse(decompressedData);
    // console.log("Decompressed data:", decodedData);
    return decodedData;
  } catch (error) {
    console.error('Error decompressing data:', error);
    return null;
  }
}

export function storeCompressedData(key, compressedData) {
  localStorage.setItem(key, compressedData);
}

export function retrieveCompressedData(key) {
  return localStorage.getItem(key);
}