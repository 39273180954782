import React, { useState, useEffect } from 'react'
import './styles.css'
import HomePage from '../HomePage'
import Login from '../Login'
import Register from '../Register'
import Dashboard from '../Dashboard'
import { CssBaseline, CircularProgress } from '@mui/material';

import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import firebase from '../firebase'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	typography: {
	  useNextVariants: true,
	},
    button: {
		textTransform: 'none'
	  },
	palette: {
	  primary: {
		light: '#63ccff',
		main: '#00416e',
		dark: '#006db3',
		contrastText: '#ffffff',
	  },
	  action: {
		disabled: '#b3b3b3',
		contrastText: '#ffffff',
	  }
	},
  });

export default function App() {

	const [firebaseInitialized, setFirebaseInitialized] = useState(false)

	useEffect(() => {
		firebase.isInitialized().then(val => {
			setFirebaseInitialized(val)
		})
	})


	return firebaseInitialized !== false ? (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/login" element={<Login/>} />
					<Route path="/register" element={<Register/>} />
					<Route path="/dashboard" element={<Dashboard/>} />
				</Routes>
			</Router>
		</ThemeProvider>
	) : <div id="loader"><CircularProgress /></div>
}