export function pad(n) {
    return n < 10 ? '0' + n : n;
}

export function prettyTime(duration) {
    /*let
        seconds = Math.floor((duration / (1000)) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        // 86388.74 milliseconds =
        // 86400 = 24 hrs ???

    // hours = (hours < 10) ? "0" + hours : hours;
    // minutes = (minutes < 10) ? "0" + minutes : minutes;
    // seconds = (seconds < 10) ? "0" + seconds : seconds;
    if (hours > 0)
        return hours + "h, " + minutes + "m";
    if (hours < 1)
        return minutes + "m, " + seconds + "s" ;
    return hours + "h, " + minutes + "m"; // + ":" + seconds + "." + milliseconds;
    */

    // https://stackoverflow.com/questions/8528382/javascript-show-milliseconds-as-dayshoursmins-without-seconds
    let cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(duration / cd),
        h = Math.floor((duration - d * cd) / ch),
        m = Math.floor((duration - d * cd - h * ch) / 60000),
        s = Math.floor((duration / (1000)) % 60);
    if (m === 60) {
        h++;
        m = 0;
    }
    if (h === 24) {
        d++;
        h = 0;
    }

    if (d < 1 && h < 1 && m < 1) return [pad(s) + 's'].join(':');
    if (d < 1 && h < 1) return [pad(m) + 'm', pad(s) + 's'].join(':');
    if (d < 1 && h > 0) return [pad(h) + 'h', pad(m) + 'm', pad(s) + 's'].join(':');
    if (d > 0 && h < 1) return [d + 'd', pad(m) + 'm', pad(s) + 's'].join(':');
    if (d > 0 && h > 0) return [d + 'd', pad(h) + 'h', pad(m) + 'm', pad(s) + 's'].join(':');


    //return [d + 'd', pad(h) + 'h', pad(m) + 'm',  pad(s) + 's'].join(':');
}

