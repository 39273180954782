import React from 'react'
import styled from 'styled-components'
import Icon from './Icon'

const Svg = styled(Icon)` 
  width: 26px; 
  height: 26px;
`

export const StatusIcon = ({ className, cls1 }) => (
    <Svg viewBox="0 0 26 26" className={className}>
        {/* <defs><style>
        .cls-1{fill:#231f20;}
        .cls-2{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}
        </style></defs> */
        }
        <circle fill={cls1} cx="13" cy="13" r="13" />
        <polyline
            fill="none"
            points="13 4.79 13 14.19 18.6 17.98"
            stroke="#fff"
            stroke-miterlimit="10"
            stroke-width="2px"
        />
    </Svg>
)