import { useEffect, useRef } from 'react';
import firebase from '../firebase';

function useFirebaseAuthListener(onLogin, onLogout) {
    const unsubscribeRef = useRef(null);
  
    useEffect(() => {
      unsubscribeRef.current = firebase.auth.onAuthStateChanged((user) => {
        if (user) {
          console.log(`user logged in: ${user.displayName}, ${user.email}`);
          onLogin();
        } else {
          console.log('user logged out!');
          onLogout();
        }
      });
  
      return () => {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
        }
      };
    }, []);
  }
  
  export default useFirebaseAuthListener;