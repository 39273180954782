import { makeStyles } from "@mui/styles";

export const CompletedActivitiesContainerStyles = makeStyles((theme) => ({
    iconwidth: {
        minWidth: '35px !important',
    },
    menuwidth: {
        width: 'inherit',
    },
    menuDiv: {
        marginLeft: '10px',
    },
    activitiesMenu: {
        fontSize: '12pt',
        verticalAlign: 'middle',
        marginBottom: '5px',
        display: 'inline-block',
    },
    tcstatusicon: { float: 'left', verticalAlign: 'text-top' },
    tctitle: {
        fontFamily: 'Montserrat',
        fontSize: '11pt',
        backgroundColor: '#00416e',
        color: '#fff',
        textAlign: 'center',
    },
    b1: {
        backgroundColor: '#fff',
        minWidth: '320px',
        marginLeft: '-5px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    daysplitterL: {
        fontFamily: 'Montserrat',
        fontSize: '10pt',
        fontWeight: '600',
        textAlign: 'left',
        marginLeft: '1px',
        color: '#00416e',
        display: 'none',
    },
    daysplitterR: {
        fontFamily: 'Montserrat',
        fontSize: '10pt',
        fontWeight: '600',
        textAlign: 'left',
        marginLeft: '50px',
        color: '#00416e',
    },
}));
