import { makeStyles } from "@mui/styles";

export const CompletedActivitiesStyles = makeStyles((theme) => ({
  tcentries: {
    backgroundColor: '#fff',
  },
  tcrow: {
    display: 'table',
  },
  tcleft: {
    fontSize: '10pt',
    fontWeight: '500',
    textAlign: 'left',
    margin: '0 5px 0 15px',
    width: '125px',
    display: 'inline-block',
    borderRight: 'solid 1px #ccc',
  },
  tcright: {
    fontSize: '10pt',
    fontWeight: '500',
    textAlign: 'left',
    marginLeft: '5px',
  },
  acright: {
    fontSize: '10pt',
    fontWeight: '500',
    textAlign: 'left',
    marginLeft: '5px',
    display: 'table-cell',
    verticalAlign: 'top',
  },
  activitylengths: {
    fontFamily: 'Montserrat',
    fontSize: '13pt',
    fontWeight: '700',
    textAlign: 'center',
    marginLeft: '5px',
    color: '#000',
  },
  daysplitterL: {
    fontFamily: 'Montserrat',
    fontSize: '10pt',
    fontWeight: '600',
    textAlign: 'left',
    marginLeft: '1px',
    color: '#00416e',
    display: 'none',
  },
  daysplitterR: {
    fontFamily: 'Montserrat',
    fontSize: '10pt',
    fontWeight: '600',
    textAlign: 'left',
    marginLeft: '50px',
    color: '#00416e',
  },
}));
