import { useEffect, useState } from "react";

export default function useStickyState(defaultValue, key) {
  // const [value, setValue] = useState(() => {
  //   const stickyValue = window.localStorage.getItem(key);
  //   return stickyValue !== null
  //     ? JSON?.parse(stickyValue)
  //     : defaultValue;
  // });
  // useEffect(() => {
  //   window.localStorage.setItem(key, JSON.stringify(value));
  // }, [key, value]);
  // return [value, setValue];
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    try {
      return stickyValue !== null ? JSON?.parse(stickyValue) : defaultValue;
    } catch
    (error) {
      console.error(`Error parsing local storage with key=${key}:`, error);
      return defaultValue;
    }
  });

/*
try {
  localStorage.setItem('todos', JSON.stringify(updatedTodos));
} catch (error) {
  if (error.name === 'QuotaExceededError') {
    console.error('Local storage quota exceeded. Consider optimizing data or using alternative storage.');
    // Implement your error handling strategy, e.g., notify the user, clear old data, etc.
  } else {
    throw error; // Re-throw other errors
  }
}
*/

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error saving to local storage: ", error);
    }
  }, [key, value]);

  return [value, setValue];
}


// export default function useSecureStickyState(defaultValue, key) {
//   const secretKey = process.env.REACT_APP_SECRET_KEY;

//   const [value, setValue] = useState(() => {
//     const encryptedValue = retrieveEncryptedData(key);
//     if (encryptedValue) {
//       const decryptedValue = decryptAndDecompress(encryptedValue, secretKey);
//       console.log(`decryptedValue: ${decryptedValue}, secretKey: ${secretKey}`)
//       return decryptedValue;
//     } else {
//       console.log(`defaultValue: ${defaultValue}`)
//       return defaultValue;
//     }
//   });

//   useEffect(() => {
//     const encryptedValue = encryptAndCompress(value, secretKey);
//     storeEncryptedData(key, encryptedValue);
//     console.log(`key: ${key}, encryptedValue: ${encryptedValue}, secretKey: ${secretKey}`)
//   }, [key, value, secretKey]);

//   return [value, setValue];
// }


// export function useSecureStickyState(defaultValue, key) {
//   const secretKey = process.env.REACT_APP_SECRET_KEY;

//   const [value, setValue] = useState(() => {
//     if (!key) {
//       console.log('Key is null or undefined');
//       return defaultValue;
//     }

//     console.log('Retrieving value for key:', key);
//     const encryptedValue = retrieveEncryptedData(key);

//     if (encryptedValue) {
//       console.log('Encrypted value:', encryptedValue);
//       const decryptedValue = decryptAndDecompress(encryptedValue, secretKey);
//       console.log('Decrypted value:', decryptedValue);
//       return decryptedValue;
//     } else {
//       console.log('Encrypted value not found for key:', key);
//       return defaultValue;
//     }
//   });

//   useEffect(() => {
//     if (key && value !== undefined && value !== null) {
//       console.log('Storing value:', value, 'for key:', key);
//       const encryptedValue = encryptAndCompress(value, secretKey);
//       console.log('Encrypted value:', encryptedValue);
//       storeEncryptedData(key, encryptedValue);
//     }
//   }, [key, value, secretKey]);

//   return [value, setValue];
// }