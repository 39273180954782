import React, { useRef } from 'react';
import { Button, Divider, Typography } from '@mui/material';

const DeleteConfirmation = ({
  title,
  subtitle,
  message,
  handleConfirm,
  handleCancel,
  isAlertOpen,
  isDiscard,
  cancelButtonReference,
}) => {
  return (
    <div className="confirmation-dialog" style={{ visibility: isAlertOpen || isDiscard ? 'visible' : 'hidden' }}>
      <Typography variant="h6" component="h2">{title}</Typography>
      {isDiscard && (<Typography variant="subtitle2" component="h6">List: {subtitle}</Typography>)}
      <Divider />
      <div style={{ marginTop: '32px' }}>
        <Typography variant="body1">{message}</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
        <Button
          variant="outlined"
          onClick={handleCancel}
          ref={cancelButtonReference} // Assign ref to the Cancel button for todo deletion
        >
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleConfirm} style={{ marginLeft: 'auto' }}>
          {isDiscard ? 'Discard' : 'Delete'}
        </Button>
      </div>
    </div>
  );
};

export default DeleteConfirmation;