import { useEffect, useState } from "react";
import { storeCompressedData, retrieveCompressedData, compressData, decompressData } from '../Utils/compressedLocalStorage';

export default function useCompressedStickyState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    if (!key) {
      // console.log('useCompressedStickyState: Key is null or undefined');
      return defaultValue;
    }

    // console.log('useCompressedStickyState: Retrieving compressed data for key:', key);
    const compressedData = retrieveCompressedData(key);

    if (compressedData) {
      // console.log('useCompressedStickyState: Compressed data retrieved:', compressedData);
      try {
        const decompressedData = decompressData(compressedData);
        // console.log('useCompressedStickyState: Decompressed data:', decompressedData, "length: ", decompressedData.length);
        return decompressedData;
      } catch (error) {
        console.error('useCompressedStickyState: Error decompressing data:', error);
        return defaultValue;
      }
    } else {
      // console.log('useCompressedStickyState: Compressed data not found for key:', key);
      return defaultValue;
    }
  });

  useEffect(() => {
    if (key && value !== undefined && value !== null) {
      // console.log('useCompressedStickyState: Storing value:', value, 'for key:', key);
      try {
        const compressedData = compressData(value);
        // console.log('useCompressedStickyState: Compressed data:', compressedData);
        storeCompressedData(key, compressedData);
      } catch (error) {
        console.error('useCompressedStickyState: Error compressing and storing value:', error);
      }
    }
  }, [key, value]);

  return [value, setValue];
}