import styled from 'styled-components';

export const PopupDiv = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 10pt;
    color: #fff;
    background-color: #666673;
`

export const PopupTitle = styled(PopupDiv)`
    font-weight: 600;
    font-size: 12pt;
    background-color: #FBB03B; 
    margin: -20px -20px 0 -20px; 
    padding: 0 13px 0 10px;
    line-height: 1.7;
`
export const PopupBody = styled(PopupDiv)`
    overflow: hidden;
    margin: -20px -20px 0 -20px; 
    padding: 0 13px 0 5px;
`

export const Description = styled(PopupDiv)`
    max-height: 110px;
    overflow-y: scroll;
    margin: 5px;
    font-style: italic; 
`

export const PopupAddress = styled(PopupDiv)`
    font-weight: 600;
    margin: 5px;
`
export const PopupLinks = styled.div`
    margin: 5px;
`

export const Link = styled.button`
  background-color: #666673;
  color: ${props => props.inputColor || "#fff"};
  font-family: 'Montserrat', sans-serif;
  font-size: 10pt;
  border: 0px;
  display: block;
  &:hover {
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const FormGroup = styled.div`
	color: palevioletred;
    display: block;
	width: 300px;
	margin: 50px auto;
`;

export const Label = styled.label`
	margin-bottom: 0.5em;
	color: palevioletred;
    display: block;
`;


export const Input = styled.input`
	padding: 0.5em;
	color: palevioletred;
	background: papayawhip;
	border: none;
	border-radius: 3px;
	width: 100%;
	margin-bottom: 0.5em;
`;

export const Message = styled.label`
	margin-bottom: 0.5em;
	color: palevioletred;
    display: block;
`;