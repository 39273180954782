
import React from 'react';
import { StoreContext } from '../Hooks/store';

function useUserInteractionTracker() {
  const { mysessiondata, myinteractions, thissessionid } = React.useContext(StoreContext);
  const [interactiondata, setinteractiondata] = myinteractions;
  const [sessionid ] = thissessionid;

  const trackInteraction = (interactionType, additionalData = {}) => {
    const newInteraction = {
      type: interactionType,
      sessionHash: sessionid ?? additionalData.sessionHash,
      timestamp: Date.now(),
      data: additionalData,
    };

    const updatedInteractionData = {
      ...setinteractiondata,
      interactions: [...(interactiondata.interactions || []), newInteraction]
    };

    // commented the line below to not log interactions data for now as its taking too much space on local storage - nov 22 2024
    //setinteractiondata(updatedInteractionData);
  };

  const clearSessionData = () => {
    localStorage.removeItem('sessiondata');
    localStorage.removeItem('interactiondata');
    localStorage.removeItem('sessionid');
  };

  return {
    trackInteraction,
    clearSessionData
  };
}

export default useUserInteractionTracker;