import React, { useState, useEffect, useRef, Suspense, useMemo } from 'react';
import { StoreContext } from '../Hooks/store';
import { notify } from "../Notifications";
import { v4 as uuidv4 } from "uuid";
import { hashCode } from '../Utils/hashCode';
import { List, ListItem, ListItemText, Checkbox, TextField, Button, ButtonGroup, Divider, Tooltip, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import styles from './TodoList.module.css'; // Import the CSS module
import sqlitedb from '../sqlitedb';

import DeleteConfirmation from '../TodoList/DeleteConfirmation';

import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';

export default function Todos() {

  const { trackInteraction } = useUserInteractionTracker();

  const dt_formatted_string = new Date(Date.now()).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
  const todo_header_default_id = (hashCode(uuidv4()));
  const todo_header_default_text = 'To-do ' + dt_formatted_string;

  const resetHeaderIdAndText = () => {
    setTodoHeader(todo_header_default_text);
    settodolistid(todo_header_default_id);
  };

  const {
    todolistsaved,
    todolistheader,
    todolistheaderid,
    thisdeviceid,
    thislocationid,
    thissessionid,
    thisuserid,
    mytodolistnames,
    modaldialog,
    showarchiveflag,
    tododirtycheck,
    mytodolists,
  } = React.useContext(StoreContext);
  const [loadedmodal, setLoadedmodal] = modaldialog;
  const [todos, setTodos] = todolistsaved;
  const [todolists, settodolists] = mytodolists;
  const [dirtytodos, setdirtytodos] = tododirtycheck;
  const [deviceid] = thisdeviceid;
  const [locationid] = thislocationid;
  const [sessionid, setSessionid] = thissessionid;
  const [userid, setUserid] = thisuserid;
  const [kvtodolistnames, setKvtodolistnames] = mytodolistnames;

  const [newTodo, setNewTodo] = useState('');
  const [isAddingTodo, setIsAddingTodo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTodoIndex, setEditingTodoIndex] = useState(null);
  const [deletingTodoId, setDeletingTodoId] = useState(null);
  const [delTodoIdx, setDelTodoIdx] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isEditingHeader, setIsEditingHeader] = useState(false);
  const [isDiscarding, setIsDiscarding] = useState(false);
  //const [isTestingdiscard, setIsTestingdiscard] = useState(false);
  const [isDeletingTodo, setIsDeletingTodo] = useState(false);


  const [todoHeader, setTodoHeader] = todolistheader;
  const [todolistid, settodolistid] = todolistheaderid;

  const completedTodos = todos.filter(todo => todo.completed).length;
  const [showarchived, setShowarchived] = showarchiveflag;

  const toggleShowArchiveFlag = () => { setShowarchived(!showarchived); };

  const todosString = useMemo(() => JSON.stringify(todos), [todos]);
  const combinedString = useMemo(() => todosString + todoHeader + todolistid, [todosString, todoHeader, todolistid]);
  const currentHash = useMemo(() => hashCode(combinedString), [combinedString]);
  const hasUnsavedChanges = useMemo(() => dirtytodos !== currentHash, [dirtytodos, currentHash]);

  useEffect(() => {
    notify('Loading Todos....')
    getTodos()
  }, []);

  const handleEditHeader = () => {
    if (todoHeaderInputRef.current) {
      todoHeaderInputRef.current.focus();
    }
    setIsEditingHeader(true);
  };

  const handleSaveHeader = () => {
    console.log('handleSaveHeader with userid:' + userid + ' todolistid:' + todolistid + ' todoHeader:' + todoHeader);
    ensureTodolistIdIsSet();
    setIsEditingHeader(false);

    if (todolistid !== '') {
      saveTodoListToDatabase(userid, todolistid, todoHeader, 0);
    } else {
      console.log('NO ID~~~~!!! handleSaveHeader with userid:' + userid + ' todolistid:' + todolistid + ' todoHeader:' + todoHeader);
      ensureTodolistIdIsSet();
      saveTodoListToDatabase(userid, todolistid, todoHeader, 0);
    }
  };

  const handleArchiveTodoList = () => {
    notify('Archiving...');
    saveTodoListToDatabase(userid, todolistid, todoHeader, 1);
    toggleShowArchiveFlag();
  };



  const handleDiscardTodoList = () => {
    console.log('handleDiscardTodoList with ' + userid + ' ' + todolistid);
    setIsDiscarding(true);

    /*
    const confirmed = window.confirm("Are you sure you want to proceed?");
    if (confirmed) {
      // Perform action if confirmed
      console.log("User confirmed");
      handleConfirmDiscard();
    } else {
      // Perform action if canceled
      console.log("User canceled");
      handleCancelDiscard();
    }
    */

  };

  const handleConfirmDiscard = async () => {
    try {
      await discardTodos(); // Wait for the database operation to complete
  
      setTodos([]);
      setIsDiscarding(false);
  
      const updatedTodoLists = todolists.filter(todolist => todolist.list_id !== todolistid);
      settodolists(updatedTodoLists);
      setShowarchived(true);
  
      resetHeaderIdAndText();
      notify('List discarded');
    } catch (error) {
      console.error('Error discarding todo list:', error);
      // Handle the error, e.g., show an error notification to the user
      notify('Error discarding list. Please try again.');
    }
  };

  const handleCancelDiscard = () => {
    setIsDiscarding(false);
  };

  const todoInputRef = useRef(null);
  const cancelButtonReference = useRef(null);
  const todoHeaderInputRef = useRef(null);

  const handleAddingTodo = () => {
    setIsAddingTodo(true);
    if (todoInputRef.current) {
      todoInputRef.current.focus();
    }
  };

  const ensureTodolistIdIsSet = () => {
    if (!todolistid || todolistid === '') {
      settodolistid(todo_header_default_id);
    }
  };

  const populateMissingProps = (todo) => ({
    ...todo,
    list_id: todo.list_id ?? todolistid,
    updated_at: todo.updated_at ?? Date.now(),
    completed_at: todo.completed_at ?? null,
    device_id: todo.device_id ?? deviceid.dvc_hash,
    location_id: todo.location_id ?? locationid.loc_hash,
    session_id: todo.session_id ?? sessionid,
    active: todo.active ?? 1,
    user_id: todo.user_id ?? userid,
  });

  async function saveTodolist() {
    notify('Saving...');
    trackInteraction('form_submit', { formName: 'Todo' });

    if (!hasUnsavedChanges) {
      console.log('No changes to save');
      return;
    }

    try {
      const todosWithDefaults = todos.map(populateMissingProps);
      console.log('Todos with defaults:', todosWithDefaults);

      await sqlitedb.saveTodos(todosWithDefaults, userid, todolistid);
      notify('To-do list saved!');
      getTodos();
    } catch (error) {
      notify('Error saving to-do list to remote server');
      console.error('saveTodolist reported error:', error);
    }
  }

  async function deleteTodoItem(userId, todoId) {
    console.log('deleteTodoItem with ' + userId + ' ' + todoId);
    //trackInteraction('form_submit', { formName: 'Todo' });

    try {
      await sqlitedb.deleteTodoItem(userid, todoId)
    } catch (error) {
      notify('Error deleting to-do item to remote server')
      console.log('deleteTodoItem reported error below...');
      console.log(error)
    }
  }

  const handleAddedTodo = () => {
    if (newTodo.trim() !== '') {
      ensureTodolistIdIsSet();
      let addtodo = {
        todo_id: hashCode(uuidv4()),
        list_id: todolistid ?? ensureTodolistIdIsSet(),
        description: newTodo,
        added_at: Date.now(),
        updated_at: Date.now(),
        completed_at: -1,
        completed: false,
        user_id: userid,
        session_id: sessionid,
        device_id: deviceid.dvc_hash,
        location_id: locationid.loc_hash,
        active: true,
      };
      const updatedTodos = [...todos, addtodo];
      setTodos(updatedTodos);
      setNewTodo('');
      setIsAddingTodo(false);
      console.log('handleAddedTodo with ' + userid + ' ' + todolistid + ' ' + newTodo + 'see object below:');
      console.dir(updatedTodos, { depth: null });
    
    //notify('Added new to-do item', true);
    }
  };

  const handleToggleTodo = (index) => {
    const updatedTodos = [...todos];
    updatedTodos[index] = {
      ...updatedTodos[index],
      completed_at:
        (!updatedTodos[index].completed && updatedTodos[index].completed_at === -1)
          ? Date.now()
          : updatedTodos[index].completed_at,
      completed: !updatedTodos[index].completed,
      updated_at: Date.now(),
    };
    setTodos(updatedTodos);
  };

  const handleSaveTodo = (index) => {
    const updatedTodos = [...todos];
    updatedTodos[index] = {
      ...updatedTodos[index],
      updated_at: Date.now(),
    };
    setTodos(updatedTodos);
    setEditingTodoIndex(null);
    setIsEditing(false);
  };

  const handleConfirmDeleteTodo = () => {
    const updatedTodos = [...todos];
    console.log('handleConfirmDeleteTodo with userid=' + userid + ' deletingTodoId=' + deletingTodoId);
    console.dir(updatedTodos, { depth: null });

    updatedTodos.splice(delTodoIdx, 1);
    setTodos(updatedTodos);

    console.dir(todos, { depth: null });

    setIsAlertOpen(false);
    setIsDeletingTodo(false);
    deleteTodoItem(userid, deletingTodoId);
    setDeletingTodoId(null);
    setDelTodoIdx(null);
  };

  const handleCancelDeleteTodo = () => {
    setDeletingTodoId(null);
    setIsAlertOpen(false);
    setIsDeletingTodo(false);
    setDelTodoIdx(null);
    notify('Canceled');
  };

  const handleDeleteTodo = (index) => {
    console.log('handleDeleteTodo with ' + userid + ' ' + index);
    if (todos && index >= 0 && index < todos.length) {
      setDeletingTodoId(todos[index].todo_id);
      setDelTodoIdx(index);
      setIsDeletingTodo(true);
      setIsAlertOpen(true);
    }
  };

  const handleEditingTodo = (index) => {
    setEditingTodoIndex(index);
    setIsEditing(true);
  };

  function updateTodoitem(index, e) {
    const updatedTodos = [...todos];
    updatedTodos[index].description = e.target.value;
    updatedTodos[index].list_id = todolistid;
    updatedTodos[index].device_id = deviceid.dvc_hash;
    updatedTodos[index].location_id = locationid.loc_hash;
    setTodos(updatedTodos);
  }

  async function saveTodoListToDatabase(userId, listId, listName, archived) {

    if (archived === 0 && !hasUnsavedChanges) {
      notify('No changes to save');
      return;
    } else {
      notify('Saving...');
    }

    console.log('Saving todo list:', { userId, listId, listName, archived });
    try {
      const result = await sqlitedb.insertOrUpdateTodoList(userId, listId, listName, archived);
      if (result) {
        const msg = `Todo list ${archived === 1 ? 'Archived' : 'Saved'} successfully: ${result}`;
        console.log(msg);

        if (archived === 1) {
          resetHeaderIdAndText();
          setTodos([]);
          toggleShowArchiveFlag();
        }

        getTodos();
        notify(msg);
      } else {
        notify(`Error ${archived === 1 ? 'Archiving' : 'Saving'}`);
      }
    } catch (error) {
      console.error(`Error ${archived === 1 ? 'Archiving' : 'Saving'} todo list for user ${userId} and list ${listId}:`, error);
      notify(`Error ${archived === 1 ? 'Archiving' : 'Saving'}: ${error.message}`);
    }
  }

  async function discardTodos() {
    return await sqlitedb.deleteTodolist(userid, todolistid);
  }

  async function getTodos() {
    try {
      let recentTodos = [];
      console.log('getTodos with ' + userid + ' ' + todolistid);
      if (todolistid) {
        console.log('1. in getTodos going to fetchExistingTodos with userid= ' + userid + ' todolistid=' + todolistid);
        recentTodos = await sqlitedb.fetchExistingTodos(userid, todolistid);
      }

      if (!recentTodos || recentTodos.length === 0) {
        notify('No todos found for user');
        console.log('No todos found for user');
      } else if (recentTodos.length > 0) {
        settodolistid(recentTodos[0].list_id);
        setTodoHeader(recentTodos[0].list_name);
      }

      setTodos(recentTodos);
      ensureTodolistIdIsSet(); // ensure todolistid is set

      setdirtytodos(hashCode(JSON.stringify(recentTodos) + todoHeader + todolistid));
    } catch (error) {
      console.error('Error fetching todos:', error);
      notify(error.message);
    }
  }

  function Loading() {
    return <h2>🌀 Loading...</h2>;
  }

  function TodolistHeaderEdit() {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);

    };

    const handleEditList = () => {
      handleEditHeader(); // Handle edit list logic
      handleMenuClose();
    };

    const handleDiscardList = () => {
      handleDiscardTodoList(); // Handle discard list logic
      handleMenuClose();
    };



    const handleArchiveList = () => {
      handleArchiveTodoList(); // Handle archive list logic
      handleMenuClose();
    };

    const handleShowArchivedLists = () => {
      setShowarchived(true); // Handle show archived lists logic
      handleMenuClose();
    };

    const handleNewList = () => {
      // Handle new list logic
      //if (hasUnsavedChanges) {
      saveTodolist();
      //}
      resetHeaderIdAndText();
      setTodos([]);
      handleMenuClose();
    };
    return <div style={{ display: 'flex', alignItems: 'center' }}>
      {isEditingHeader ? (
        <>
          <TextField autoFocus multiline 
                        style={ {width: '100%'}}
            ref={todoHeaderInputRef}
            value={todoHeader}
            // onChange={(e) => setTodoHeader(e.target.value)}
            onChange={(e) => {
              setTodoHeader(e.target.value);
              // Set selectionStart to the current position after updating value
              e.target.selectionStart = e.target.selectionEnd;
              //ensureTodolistIdIsSet();
            }}
            onBlur={handleSaveHeader}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSaveHeader();
              }
            }}
          />
          <Tooltip title="Save the name of this list" className={styles.tooltip}>
            <SaveOutlinedIcon onClick={handleSaveHeader} />
          </Tooltip>
        </>
      ) : (
        <>
          <Typography variant="h6" component="h2" onClick={handleEditHeader} className={styles.typography}>{todoHeader}</Typography>
          <IconButton aria-label="More Options" onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-basic"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleEditList}>Edit List Name</MenuItem>
            <MenuItem onClick={handleArchiveList}>Archive List</MenuItem>
            <MenuItem onClick={handleDiscardList}>Discard List</MenuItem>
            <MenuItem onClick={handleNewList}>Start a New List</MenuItem>
            {/* <MenuItem onClick={handleTestDiscard}>Test Discard</MenuItem> */}
            <Divider />
            <MenuItem onClick={handleShowArchivedLists}>Show All Lists</MenuItem>
          </Menu>
        </>
      )}
    </div>;
  }

  function AddNewTodoItem() {
    useEffect(() => {
      if (todoInputRef.current) {
        todoInputRef.current.selectionStart = todoInputRef.current.selectionEnd;
      }
    }, [newTodo]); // Re-run only when newTodo changes
    return (
      <div className={`${styles.todoContainer} ${isAlertOpen ? styles.hidden : styles.visible}`}>
        {isAddingTodo ? (
          <>
            <TextField autoFocus multiline 
                        style={ {width: '100%'}}
               ref={todoInputRef}
              value={newTodo}
              onChange={(e) => {
                setNewTodo(e.target.value);
              }}
              selectionStart={newTodo.length} // Set to current length after each change
              onBlur={() => {
                if (newTodo.trim() === '') {
                  setIsAddingTodo(false);
                }
                if (newTodo.trim() !== '') {
                  handleAddedTodo();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddedTodo();
                } else if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                  // Check if cursor is at the beginning/end of the text
                  if ((e.key === 'ArrowUp' && e.target.selectionStart === 0) ||
                      (e.key === 'ArrowDown' && e.target.selectionStart === e.target.value.length)) {
                    e.preventDefault();  // Prevent default behavior only if at the edge
                  }
                }
              }}
              />
            <div className={styles.todoContainer} style={{ opacity: newTodo.trim() === '' ? 0.5 : 1, pointerEvents: newTodo.trim() === '' ? 'none' : 'auto' }}>
              &nbsp;&nbsp;
              <SaveOutlinedIcon className="hide-on-mobile" onClick={handleAddedTodo} />
            </div>
          </>
        ) : (
          <>
            <Button variant="contained" color="primary" onClick={handleAddingTodo}>
              <AddOutlinedIcon />
            </Button>
            &nbsp;&nbsp;
            {todos.length > 1 && (
              <Tooltip title={`${completedTodos} of ${todos.length} completed`}>
                <span style={{ fontSize: '12px', marginLeft: '5px' }}>
                  {completedTodos} of {todos.length} completed.
                </span>
              </Tooltip>
            )}
          </>
        )}
      </div>
    )
  }

  function TodoFooter() {
    return (
      <footer style={{ display: isDeletingTodo || isDiscarding ? 'none' : 'flex' }}>
        <div>
          {todos.length > 0 && (
            <SaveArchiveOrDiscardButtons />
          )}
        </div>
      </footer>
    );
  }

  function SaveArchiveOrDiscardButtons() {
    return (
      <ButtonGroup variant="outlined" aria-label="Loading button group">
        <Tooltip title="Save this list">
          <IconButton aria-label="Save this list" onClick={() => saveTodolist()}>
            {/* <Button onClick={() => saveTodolist()} variant="contained" color="success">Save</Button> */}
            <Button variant="contained" color="success">Save</Button>
          </IconButton>
        </Tooltip>
        &nbsp;&nbsp;&nbsp;
        <Tooltip title="Archive this list">
          <IconButton aria-label="Archive this list" onClick={handleArchiveTodoList}>
            <Button variant="outlined" color="success">Archive</Button>
          </IconButton>
        </Tooltip>
        &nbsp;&nbsp;&nbsp;
        <Tooltip title={`Discard list and its contents`}>
          <IconButton aria-label={`Discard "${todoHeader}"`} onClick={handleDiscardTodoList}>
            <Button variant="outlined" color="error">Discard</Button>
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    );
  }

  useEffect(() => {
    if (isAlertOpen || isDiscarding) {
      document.body.style.overflow = 'hidden';
      const confirmationElement = document.querySelector('.confirmation-dialog');
      if (confirmationElement) {
        confirmationElement.scrollIntoView({ behavior: 'smooth' });
      }
      if (cancelButtonReference.current) {
        cancelButtonReference.current.focus();
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isAlertOpen]);


  return (
    <>
      {isDiscarding && (
        <div style={{ width: '100%', visibility: isDiscarding ? 'visible' : 'hidden' }}>
          <DeleteConfirmation
            title="Discard List?"
            message="This action cannot be undone!"
            isDiscard={isDiscarding}
            handleConfirm={handleConfirmDiscard}
            handleCancel={handleCancelDiscard}
            cancelButtonReference={cancelButtonReference}
          />
        </div>
      )}
      {isAlertOpen && (
        <div style={{ width: '100%', visibility: isAlertOpen ? 'visible' : 'hidden' }}>
          <DeleteConfirmation
            title="Delete To-do?"
            subtitle={todoHeader}
            message="Once deleted, you cannot recover this item."
            isAlertOpen={isAlertOpen}
            handleConfirm={() => handleConfirmDeleteTodo()}
            handleCancel={handleCancelDeleteTodo}
            cancelButtonReference={cancelButtonReference}
          />
        </div>
      )}

      <div style={{ width: '100%', visibility: (isAlertOpen || isDiscarding) ? 'hidden' : 'visible' }}>
        {TodolistHeaderEdit()}
        {AddNewTodoItem ()}

        <div style={{ marginTop: '5px', marginBottom: '5px', visibility: (isAlertOpen || isDiscarding) ? 'hidden' : 'visible' }}>
          <Suspense fallback={<Loading />}>
            <List>
              {/* {todos.filter(todo => (todo.active || todo.active === 1)).map((todo, index) => { */}
              {todos.map((todo, index) => {
                return (
                  <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={todo.completed} onChange={() => handleToggleTodo(index)} />
                    {editingTodoIndex === index ? (
                      <>
                        <TextField autoFocus multiline 
                        style={{width: '100%'}}
                          value={todo.description}
                          // onChange={(e) => {
                          //   updateTodoitem(index, e);
                          // }}
                          onChange={(e) => {
                            updateTodoitem(index, e);
                            // Set selectionStart to the current position after updating value
                            e.target.selectionStart = e.target.selectionEnd;
                          }}
                          onBlur={() => {
                            handleSaveTodo(index);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSaveTodo(index);
                            }
                          }}
                          />
                        <SaveOutlinedIcon className="hide-on-mobile" onClick={() => handleSaveTodo(index)} />
                      </>
                    ) : (
                      <>
                        <ListItemText primary={todo.description} onClick={() => handleEditingTodo(index)} />
                        <Tooltip title="Edit this item" className={`${styles.tooltip} hide-on-mobile`}>
                          <IconButton aria-label="Edit this item" className="hide-on-mobile" onClick={() => handleEditingTodo(index)}>
                            <EditNoteOutlinedIcon className="hide-on-mobile" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`Remove this item from the list`} className={styles.tooltip}>
                          <DeleteOutlinedIcon onClick={() => handleDeleteTodo(index)} style={{ verticalAlign: 'middle' }} />
                        </Tooltip>
                      </>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Suspense>
        </div>
        {todos.length > 0 && (
          TodoFooter()
        )}
      </div>


    </>
  );



}