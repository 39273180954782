import React, { useState, useEffect } from 'react';

function CurrentDateV2({ format = 'short' }) {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000 * 60); // Update every minute

    return () => clearInterval(intervalId);
  }, []);

  const formattedDate = currentDate.toLocaleDateString('en-US', {
    month: format === 'short' ? 'short' : 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <div>
      <p>{formattedDate}</p>
    </div>
  );
}

export default CurrentDateV2;