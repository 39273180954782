export function hashCode(s) {
  for (var i = 0, h = 0; i < s.length; i++)
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;
  //console.log(`in hashCode with ${s}. Output hash=${h}`);
  return h;
}

function hashCode_bitshift(str) {
  // note this function generates the same hash as the above hashCode function
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  //console.log(`in hashString with ${str}. Output hash=${hash}`);
  return hash;
}


/*
Script 1: hashCode
JavaScript

function hashCode(s) {
  for (var i = 0, h = 0; i < s.length; i++)
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;
  return h;
}

Multiplication Factor: Uses Math.imul(31, h) which performs a 32-bit integer multiplication with 31.
Bitwise OR: Uses | 0 to ensure the result is a 32-bit integer.
Algorithm: This is a common hash function used in Java, known for its simplicity and reasonable distribution.


Script 2: hashString
JavaScript

function hashString(str) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

Bitwise Shift: Uses hash = (hash << 5) - hash + char, which is equivalent to multiplying by 31 and then adding the character code.
Bitwise OR: Also uses |= 0 to ensure the result is a 32-bit integer.
Algorithm: This is a variation of the DJB2 hash function, which is known for its simplicity and good distribution properties.

Summary
Multiplication vs. Bitwise Shift: The first script uses Math.imul for multiplication, while 
the second script uses a bitwise shift and subtraction to achieve a similar effect.
Hash Calculation: Both scripts aim to generate a hash code, 
but they use different methods to combine the character codes.
While both functions aim to generate a hash code for a string, they use different algorithms 
and techniques, resulting in different hash values for the same input. 

If you need consistent hash values, 
you should choose one function and use it consistently 
throughout your application.
*/