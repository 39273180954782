import React from 'react';
import { Typography, Paper, Avatar, Button } from '@mui/material';
import VerifiedUserOutlined from '@mui/icons-material/VerifiedUserOutlined';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const Main = styled('main')(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  [theme.breakpoints.up(400 + theme.spacing(3))]: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

function HomePage() {
  return (
    <Main>
      <StyledPaper>
        <StyledAvatar>
          <VerifiedUserOutlined />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Hello Guest!
        </Typography>
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          component={Link}
          to="/register"
        >
          Register
        </SubmitButton>
        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          component={Link}
          to="/login"
        >
          Login
        </SubmitButton>
        {/* <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          component={Link}
          to="/dashboard"
        >
          Dashboard
        </SubmitButton> */}
      </StyledPaper>
    </Main>
  );
}

export default HomePage;