import React, { useState, useContext } from 'react';
import { notify } from "../Notifications";
import { prettyTime } from '../Utils/prettyTime';
import { StoreContext } from '../Hooks/store';
import { Divider } from '@mui/material';
import { CompletedActivitiesStyles } from '../Styling/CompletedActivitiesStyles';
import { IconArrowDownUp } from '../Shared/IconArrowDownUp';

export function CompletedActivities() {
  const classes = CompletedActivitiesStyles();
  const { activitiescustom, activitiesselected, activitiessaved } = useContext(StoreContext);
  const [donecustom] = activitiescustom;
  const [doneselect] = activitiesselected;
  const [savedactivities, setsavedactivities] = activitiessaved;
  const [isDescending, setIsDescending] = useState(true);
  const completedItems = [...donecustom, ...doneselect, ...savedactivities];

  const toggleSortOrder = () => {
    setIsDescending(!isDescending);
    notify('Loading....');
    savedactivities.sort((a, b) => (isDescending ? b.utc_saved - a.utc_saved : a.utc_saved - b.utc_saved));
  };

  const doneCount = completedItems.length;

  const ActivitiesHeader = React.memo(() => (
    <div className={classes.tcentries}>
      <div>
        <span className={classes.tcleft}><strong>Activity</strong></span>
        <span className={classes.tcright}><strong>Duration</strong></span>
      </div>
      <Divider />
    </div>
  ));

  const CompletedItems = React.memo(() => (
    <>
      <div className={classes.activitylengths}>Completed Activities... ({doneCount}) &nbsp;&nbsp;
        <span onClick={toggleSortOrder}>
          <IconArrowDownUp />
        </span></div>
      <Divider />
      <div className={classes.tcentries}>
        <ActivitiesHeader />
        {completedItems.map((i) => {
          return (
            <>
              <div className={classes.tcrow}>
                <span className={(i.utc_start === '' ? classes.daysplitterL : classes.tcleft)}>{i.utc_start === '' ? '' : `${i.activity}`}</span>
                <span className={(i.utc_start === '' ? classes.daysplitterR : classes.acright)}>{i.utc_start === '' ? i.activity : `${i.local_start}-${i.local_end} (${prettyTime(1000 * i.elapsed)})`}</span>
              </div>
              <Divider />
            </>
          );
        })}
      </div>
    </>
  ));
  return <CompletedItems />
}
