import React, { useEffect } from 'react';

import sqlitedb from '../sqlitedb';
import { StoreContext } from '../Hooks/store';
import { notify } from '../Notifications';

import { ListItemButton, ListItemText, Switch, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(() => ({
    smallerText: {
        fontSize: '6px !important',
        color: 'grey !important',
        fontWeight: 'lighter !important'
    },
    switchRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        cursor: 'default !important'
    },
}));

export default function ArchivedTodolists() {
    const {
        todolistheader,
        todolistheaderid,
        showarchiveflag,
        mytodolists,
        thisuserid,
    } = React.useContext(StoreContext);

    const classes = useStyles();

    const [showarchived, setShowarchived] = showarchiveflag;
    const [userid] = thisuserid;
    const [todolists, settodolists] = mytodolists;
    const [, settodolistid] = todolistheaderid;
    const [, setTodoHeader] = todolistheader;

    useEffect(() => {
        const fetchTodolists = async () => {
            notify('Loading TodoLists...');
            console.log('getTodolist() for user:', userid);

            try {
                const todolists = await sqlitedb.get_user_todolists(userid);
                settodolists(todolists);
                console.log('Successfully fetched todo lists:', todolists);
            } catch (error) {
                notify('Error loading todo lists:', error.message);
                console.error('Error fetching todo lists:', error);
            }
        };

        fetchTodolists();
    }, [showarchived]);

    const handleShowClick = (list_id, list_name) => {
        console.log('Show clicked for list:', list_id, list_name);
        settodolistid(list_id);
        setTodoHeader(list_name);
        setShowarchived(false);
    };

    return (
        <div style={{ height: 300, width: '100%' }}>
            <h2>All To-Do Lists</h2>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                    <Typography variant="subtitle1">List Name</Typography>
                    <Typography variant="subtitle1">Status</Typography>
                </div>
                {todolists.map(todolist => (
                    <ListItemButton key={todolist.list_id} onClick={() => handleShowClick(todolist.list_id, todolist.list_name)}>
                        <ListItemText primary={todolist.list_name} />
                        <div style={{ flexGrow: 1 }} /> {/* Push the Switch to the right */}
                        <div className={classes.switchRight}>
                            <Switch
                                disabled
                                edge="end"
                                checked={todolist.archived === 0}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-archive',
                                }}
                            />
                            <ListItemText id="switch-list-label-archive" primary={todolist.archived === 1 ? "(archived)" : ""} className={classes.smallerText} />
                        </div>
                    </ListItemButton>
                ))}
            </div>
        </div>
    );
}