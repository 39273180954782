import React from 'react';
import styled from 'styled-components';
import ee from 'event-emitter';

// https://www.youtube.com/watch?v=ahSz_bmePNk&t=286s

const Container = styled.div`
background-color: #444;
color: white;
padding: 16px;
position: absolute;
top: ${props => props.top}px;
right: 16px;
z-index: 999;
transition: top 0.5s ease;

> i {
    margin-left: 8px;
}
`;

const emitter = new ee();

export const notify = (msg, persist = false) => {
    //console.log('in notify: ' + msg);
    emitter.emit('notification', { msg, persist }); // Send both msg and persist flag
  }
  
  export default class Notifications extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        top: -400,
        msg: '',
        persist: false, // Default persist flag to false
      };
  
      this.timeout = null;
  
      emitter.on('notification', (data) => {
        this.onShow(data.msg, data.persist); // Use data object for msg and persist flag
      });
    }
  
    onShow = (msg, persist = false) => { // Accept persist flag in onShow
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
  
      this.setState({ top: -100 }, () => {
        this.timeout = setTimeout(() => {
          this.showNotification(msg, persist);
        }, 500);
      });
    }
  
    showNotification = (msg, persist) => {
      this.setState({
        top: 16,
        msg: msg,
        persist: persist, // Update internal persist state
      });
  
      if (!persist) { // Clear timeout only if not persisting
        setTimeout(() => {
          this.setState({
            top: -100,
          });
        }, 3000);
      }
    }
  
    render() {
      return (
        <Container top={this.state.top}>
          {this.state.msg}
          <i className="fa fa-bell" />
        </Container>
      );
    }
  }

/*
export const notify = (msg) => {
    //console.log('in notify: ' + msg);
    emitter.emit('notification', msg);
}

export default class Notifications extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            top: -400,
            msg: '',
        };

        this.timeout = null;

        emitter.on('notification', (msg) => {
            this.onShow(msg);
        });
    }

    onShow = (msg) => {
        if(this.timeout){
            clearTimeout(this.timeout);
            this.setState({top: -100}, () => {
                this.timeout = setTimeout(() => {
                    this.showNotification(msg);
                }, 500);
            });
        } else{
            this.showNotification(msg);
        }
    }

    showNotification = (msg) => {
        this.setState({
            top: 16,
            msg: msg,
        }, () => {
            setTimeout(() => {
                this.setState({
                    top: -100,
                });
            }, 3000);
        });
    }

    render() {
        return(
            <Container top={this.state.top}> {this.state.msg}<i className="fa fa-bell"></i></Container>
        );
    }
}
*/