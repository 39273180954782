import React, { useState } from 'react';
import { IconButton, Container, Dialog, DialogTitle, DialogContent, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';
import { notify } from '../Notifications';
import CurrentDate from '../Utils/CurrentDate';
import ActivityTimer from './ActivityTimer';
import { StoreContext } from '../Hooks/store';
import { CompletedActivities } from './CompletedActivities';
import { addToStringArray, sortStringArray } from '../Utils/arrayUtils';
import { CancelBtn, SaveBtn, InputText } from './ActivityTimer.styled';
import useStickyState from '../Hooks/useStickyState';
import { CompletedActivitiesContainerStyles } from '../Styling/CompletedActivitiesContainerStyles';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';

export default function Homework(props) {
    const { trackInteraction } = useUserInteractionTracker();

    const {
        modaldialog,
        activitiesclockedin,
        activitiesaddvisible } = React.useContext(StoreContext);
    const [clockedin] = activitiesclockedin;
    const [, setLoadedmodal] = modaldialog;
    const [showaddactivity, setShowaddactivity] = activitiesaddvisible;

    const [, setHomeworkopen] = useState(props.show)
    const [menucolor] = useState(props.rendercolor)

    const [newChoiceTxt, setNewChoiceTxt] = useState('')
    const [choices, setChoices] = useStickyState(['', 'Break', 'Lunch', 'Piano',
        'Math', 'DreamBox', 'Math-Packet', 'Math-IXL',
        'Reading', 'Comprehension', 'Writing',
        'Science', 'Walk', 'Workout'], 'homeworkchoices');

    // Ensure choices is an array before sorting 
    if (Array.isArray(choices)) { 
        choices.sort(sortStringArray); 
    }
    //choices.sort(sortStringArray); //choices.sort(sortStringArray).reverse();

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const classes = CompletedActivitiesContainerStyles();

    //let homeworkicon = useViewport > 620 ? <WatchOutlinedIcon color="secondary" /> : <WatchOutlinedIcon style={{color: '#fff'}} />
    let homeworkicon = menucolor === 'primary' ? <WatchOutlinedIcon color="primary" /> : <WatchOutlinedIcon style={{ color: menucolor }} />

    const [open, setOpen] = useState(false);

    function handleOnClose() {
        setLoadedmodal("")
        setOpen(false)
        setHomeworkopen(false)
        trackInteraction('button_click', { buttonText: 'Homework.close' });
    }

    function handleOnOpen() {
        setLoadedmodal("Homework")
        setOpen(true)
        trackInteraction('page_view', { pageName: 'Homework' });
    }



    function addChoice() {
        trackInteraction('button_click', { buttonText: 'Homework.addChoice' });
        setShowaddactivity(false);
        if (choices.includes(newChoiceTxt)) {
            notify(`Won't add duplicate: ${newChoiceTxt}`);
            return;
        }

        let mutatedChoices = choices => [...choices, newChoiceTxt];
        setChoices(mutatedChoices)
        let b = [...choices, newChoiceTxt];
        setChoices(addToStringArray(choices, newChoiceTxt).sort(sortStringArray));
        notify(`Added ${newChoiceTxt}`);
        trackInteraction('button_click', { buttonText: 'Homework.addChoice', output: newChoiceTxt});
    }

    function cancelAddChoice() {
        setShowaddactivity(false);
        setNewChoiceTxt('');
    }

    function handleOnChange(e) {
        setNewChoiceTxt(e.target.value);
    }

    //merge the two object arrays
    const AllCompletedActivities = CompletedActivities();

    const AddActivity =
        <>
            <InputText
                maxLength="30"
                onChange={handleOnChange}
                placeholder='Enter activity...'
                onMouseEnter={() => {
                    if (newChoiceTxt === 'Enter activity...') {
                        setNewChoiceTxt('');
                    }
                }}
            ></InputText>

            <CancelBtn
                inputColor='#333'
                onClick={() => cancelAddChoice()}>
                Cancel
            </CancelBtn>

            <SaveBtn
                inputColor='#0080BB'
                onClick={() => addChoice()}>
                Save
            </SaveBtn>

        </>
        ;

    return (
        <Container>
            <div className={classes.menuDiv} onClick={handleOnOpen}>
                {/* {homeworkicon} */}
                <span className={classes.activitiesMenu}>&nbsp;&nbsp;Activities</span>
            </div>

            <Dialog open={open} onClose={handleOnClose}>
                <DialogTitle className={classes.tctitle}>
                    <CurrentDate options={dateOptions} />
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleOnClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.b1}>
                    <div><strong><ActivityTimer color='#0080BB' activity='Begin/End' /></strong></div>
                    {!clockedin && (
                        <label><strong><i>Please Begin to get started</i></strong></label>
                    )}
                    <Divider />
                    <div><ActivityTimer color='orange' activity='Select' placeholder='Select activity:' options={choices} /></div>
                    <div>
                        {showaddactivity && AddActivity}
                    </div>
                    <div><ActivityTimer color='green' activity='Custom' placeholder='' /></div>
                    <Divider />
                    <CompletedActivities />
                </DialogContent>
            </Dialog>

        </Container>
    );
}
