import { createClient } from "@libsql/client/web";
import firebase from "./firebase";
import { hashCode } from './Utils/hashCode';
import { useEffect } from "react";

const turso = createClient({
	url: process.env.REACT_APP_TURSO_DATABASE_URL,
	authToken: process.env.REACT_APP_TURSO_AUTH_TOKEN,
});

const createCheckExistingListStatement = (listId) => {
	const statement = {
	  sql: "select list_id from todo_list where list_id = ?;",
	  args: [listId],
	};
  
	console.log('createCheckExistingListStatement');
	console.log(statement);
	return statement;
  };
  
  const createUpdateTodoListStatement = (listId, listName, userId, archived) => {
	const statement = {
	  sql: `
		UPDATE todo_list
		SET list_name = ?, user_id = ?, archived = ?, added_at = ?
		WHERE list_id = ?
	  `,
	  args: [listName, userId, archived, Date.now(), listId],
	};
  
	console.log('createUpdateTodoListStatement');
	console.log(statement);
	return statement;
  };
  
  const createInsertTodoListStatement = (listId, listName, userId, archived) => {
	const statement = {
	  sql: `
		INSERT INTO todo_list (list_id, list_name, user_id, archived, added_at)
		VALUES (?, ?, ?, ?, ?)
	  `,
	  args: [listId, listName, userId, archived, Date.now()],
	};
  
	console.log('createInsertTodoListStatement');
	console.log(statement);
	return statement;
  };
  


class Sqlitedb {

	async get_turso_test() {
		let obj = await turso.execute("SELECT * FROM users");
		return obj.rows;
	}

	async get_user_todolists(userId) {
		console.log('sqlitedb.get_user_todolists')
		//sql: "SELECT * FROM todo_list WHERE user_id = :uid AND archived = 1",
		let obj = await turso.execute({
			sql: "SELECT * FROM todo_list WHERE user_id = :uid order by updated_at desc;",
			args: { uid: userId },
		});
		console.dir(obj.rows[0], { depth: null });
		return obj.rows;
	}

	
	async insertOrUpdateTodoList(userId, listId, listName, archived) {
		console.group('insertOrUpdateTodoList');
		console.log('insertOrUpdateTodoList with userId=' + userId + " and listId=" + listId + " and listName=" + listName);
		const checkExistingListStatement = createCheckExistingListStatement(listId);
		const result = await turso.execute(checkExistingListStatement.sql, checkExistingListStatement.args);
		const exists = result?.rows[0]?.list_id === listId ? true : false;
	  
		let db = null;

		if (exists) {
		  const updateStatement = createUpdateTodoListStatement(listId, listName, userId, archived);
		  console.log('insertOrUpdateTodoList -- UPDATING!'); 
		  
		  db = await turso.execute(updateStatement.sql, updateStatement.args);

		  /*if (db.rowsAffected > 0) {
			console.log('Todo list updated successfully. Marking todo items as inactive...');
			const db2 = await turso.execute({
				sql: "update todo set active = 0 where user_id = :uid and list_id = :lid",
				args: { uid: userId, lid: listId },
			});
			if (db2.rowsAffected > 0) {
			  console.log('Todo items marked as inactive successfully for list ID:', listId);
			}
		  }*/

		} else {
		  const insertStatement = createInsertTodoListStatement(listId, listName, userId, archived);
		  console.log('insertOrUpdateTodoList -- INSERTING!');

		  db = await turso.execute(insertStatement.sql, insertStatement.args);
		}
		
		console.dir(db, { depth: null });
		console.log(db.rowsAffected);
	}
	
	
	/*
	async getHash(h) {
		//const hash_id = hashCode(this.getCurrentAppUserInfo());
		console.log('in sqlite.getHash');
		const hash_sql = `select hash_value from hash_lookup where hash_key = ${h};`
		let obj = await turso.execute(hash_sql);
		console.log('getHash');
		console.log(obj.rows);
		return obj.rows;
	}

	async getHashes() {
		console.log('in sqlite.getHashes');
		const hash_sql = `select hash_key, hash_value from hash_lookup;`
		let obj = await turso.execute(hash_sql);
		console.log('getHashes');
		console.log(obj.rows);
		return obj.rows;
	}
	*/

	async saveHash(k, v) {
		//console.log('in sqlite.saveHash');
		//const hash_id = hashCode(this.getCurrentAppUserInfo());
		const hash_sql = `insert into hash_lookup (hash_key, hash_value) values (${k}, '${v}');`
		//console.log('hash_sql');
		//console.log(hash_sql);
		let obj = await turso.execute(hash_sql);
		//console.log(obj);
		return obj;
	}

	async getTimeCardEntriesForLast5days() {
		//console.log('in Sqlitedb.getTimeCardEntriesForDate');
		if (!firebase.isLoggedIn) {
			return alert('Not authorized')
		}

		let tc_data = await turso.execute("select * from time_card where utc_saved > (strftime('%s', 'now', '-5 day')*1000);");
		//console.log(tc_data);
		return tc_data.rows;
	}

	//
	async getActivitiesForLast5days() {
		//console.log('in Sqlitedb.getActivitiesForLast5days');
		if (!firebase.isLoggedIn) {
			return alert('Not authorized')
		}

		const user_id = this.getCurrentAppUserInfo();
		const fetch_activities_sql = `select * from activity where user_id = '${user_id}';`;
		//console.log(fetch_activities_sql);
		let acts = await turso.execute(fetch_activities_sql);
		//console.log(acts);
		return acts.rows;
	}

	async turso_save_time_card(tc) {
		// let db = await turso.execute({
		// 	sql: "INSERT INTO activity VALUES (:user_id)",
		// 	args: { user_id: this.auth.currentUser},
		// });
		//let userIdStr = this.auth.currentUser.uid + "~" + this.getCurrentUserEmail();
		let user_id = this.getCurrentAppUserInfo();
		let db = await turso.execute({
			sql: "INSERT INTO time_card VALUES (:user_id, :event_name, :event_duration_ms, :event_date, :utc_start, :utc_end, :utc_saved, :latitude, :longitude)",
			args: { user_id: user_id, event_name: tc.event_name, event_duration_ms: tc.event_duration_ms, event_date: tc.event_date, utc_start: tc.utc_start, utc_end: tc.utc_end, utc_saved: Date.now(), latitude: tc.latitude, longitude: tc.longitude },
		});
		//let tbRows = db.rows;
		//console.log(db.rowsAffected);
		return db;
	}

	async turso_save_activities(activities) {

		const statements = activities.map((act) => ({
			sql: "INSERT INTO activity VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)",
			args: [
				act.user_id,
				act.activity,
				act.elapsed,
				act.elapsedlabel,
				act.local_start,
				act.local_end,
				act.utc_start,
				act.utc_end,
				act.device_info,
				act.utc_saved
			],
		}));

		let db = await turso.batch(statements, 'write');
		return db;
	}

	async fetchRecentTodos(userId) {
		console.group('in fetchRecentTodos with userId=' + userId);
		const query = {
			sql: `
			  SELECT list_id
			  FROM todo
			  WHERE user_id = :userId
			  AND active = 1
			  ORDER BY added_at DESC
			  LIMIT 1;
			`,
			args: {
			  userId: userId
			}
		  };

		  try {
			const result = await turso.execute(query);
			console.log('fetchRecentTodos with: ')
			console.table(result)


			if (result && result.rows && result.rows.length === 0) {
				console.warn('No active todo found for user:', userId);
				return []; // Or handle the case as needed, e.g., return a default list ID
			  }

			const listId = result.rows[0].list_id;
			return this.fetchExistingTodos(userId, listId);
		  } catch (error) {
			console.error('Error fetching most recent todos:', error);
			throw error;
		  }
	}

	async discardTodos(userId, listId) {
		console.group('delediscardTodoseTodos')
		console.log('sqlite.discardTodos')
		//console.log('sqlite.discardTodos with userId=' + userId + " and listid=" + listId)
		try {
			let db = await turso.execute({
				sql: "delete from todo where user_id = :uid and list_id = :lid",
				args: { uid: userId, lid: listId },
			});
			return db;
		} catch (error) {
			console.error('Error discarding todos:', error);
			throw error; // Re-throw the error to handle it in the calling function
		}
	}

	async deleteTodolist(userId, listId) {
		console.group('deleteTodolist')
		console.log('deleteTodolist (userId, listId) --> ', userId, listId);
		try {
			  const result = await turso.execute({
				sql:  "delete from todo where user_id = :uid and list_id = :lid",
				args: { uid: userId, lid: listId },
			})

			console.log('Rows affected:', result.rowsAffected);

			if (result.rowsAffected > 0) {
				console.log('Todos deleted successfully');

				const result2 = await turso.execute({
					sql:  "delete from todo_list where user_id = :uid and list_id = :lid",
					args: { uid: userId, lid: listId },
				})
				if (result2.rowsAffected > 0) {
					console.log('Todo list deleted successfully');
				}
			} else {
				console.warn('No todos were deleted for the given user ID and list ID');
			}
		} catch (error) {
			console.error('Error deleting todos:', error);
			throw error; // Re-throw the error to handle it in the calling function
		}
	}

	async deleteTodoItem(userId, todoId) {
		console.group('deleteTodoItem')
		console.log('deleteTodoItem (userId, todoId) --> ', userId, todoId);
		try {
			  const result = await turso.execute({
				sql:  "delete from todo where user_id = :uid and todo_id = :tid",
				args: { uid: userId, tid: todoId },
			})

			console.log('Rows affected:', result.rowsAffected);

			if (result.rowsAffected > 0) {
				console.log('Todo item deleted successfully');
			} else {
				console.warn('Todo item not deleted for ' + userId + ' and ' + todoId);
			}
		} catch (error) {
			console.error('Error deleting todo item ' + todoId + ' error details: ', error);
			throw error; // Re-throw the error to handle it in the calling function
		}
	}

	// BELOW CODE WORKS!
	// async fetchTodos(userId, listId) {
	// 	try {
	// 		let todo_data = await turso.execute({
	// 			sql: "select * from todo where user_id = :uid and list_id = :lid",
	// 			args: { uid: userId, lid: listId },
	// 		});

	// 		return todo_data.rows;
	// 	} catch (error) {
	// 		console.error('Error fetching todos:', error);
	// 		throw error; // Re-throw the error to handle it in the calling function
	// 	}
	// }

	async saveTodos(newTodos, userId, listId) {
		console.group('saveTodos')
		const existingTodos = await this.fetchExistingTodos(userId, listId);
		console.log('existingTodos');
		console.dir(existingTodos, { depth: null });
		console.dir(newTodos, { depth: null });

		const statements = newTodos.map((newTodo) => {
		  const existingTodo = existingTodos.find((todo) => todo.todo_id === newTodo.todo_id);

		  const sql = existingTodo
			? `
				UPDATE todo
				SET description = :description,
					updated_at = :updated_at,
					completed_at = :completed_at,
					completed = :completed,
					device_id = :device_id,
					location_id = :location_id,
					session_id = :session_id,
					active = :active
				WHERE todo_id = :todo_id AND user_id = :user_id AND list_id = :list_id
			  `
			: `
			  INSERT INTO todo (todo_id, list_id, description, added_at, updated_at, completed_at, completed, user_id, session_id, device_id, location_id, active)
			  VALUES (:todo_id, :list_id, :description, :added_at, :updated_at, :completed_at, :completed, :user_id, :session_id, :device_id, :location_id, :active)
			`;

		  const args = existingTodo
			? {
				description: newTodo.description,
				updated_at: newTodo.updated_at,
				completed_at: newTodo.completed_at,
				completed: newTodo.completed,
				device_id: newTodo.device_id,
				location_id: newTodo.location_id,
				session_id: newTodo.session_id,
				active: newTodo.active,
				list_id: newTodo.list_id,
				todo_id: newTodo.todo_id,
				user_id: newTodo.user_id,
			  }
			: {
				todo_id: newTodo.todo_id,
				list_id: newTodo.list_id,
				description: newTodo.description,
				added_at: newTodo.added_at ?? Date.now(),
				updated_at: newTodo.updated_at,
				completed_at: newTodo.completed_at,
				completed: newTodo.completed,
				user_id: newTodo.user_id,
				session_id: newTodo.session_id,
				device_id: newTodo.device_id,
				location_id: newTodo.location_id,
				active: newTodo.active,
			  };

		  return { sql, args };
		});

		console.log('sql statements below in db call');
		console.log(statements);

		try {
		  await turso.batch(statements, 'write');
		  console.log('Todos saved/updated successfully');
		} catch (error) {
		  console.error('Error saving/updating todos:', error);
		  throw error;
		}
	  }

	  async fetchExistingTodos(userId, listId) {
		console.log('fetchExistingTodos with userId=' + userId + ' and list_id=' + listId);
		const query = {
		  sql: `
			select a.todo_id, a.list_id, b.list_name, a.description, a.completed
			from todo a join todo_list b on b.list_id = a.list_id 
			where a.user_id = :userId AND a.list_id = :listId order by a.added_at desc;
		  `, //AND active = 1
		  args: {
			userId: userId,
			listId: listId
		  }
		};

		try {
		  const result = await turso.execute(query);
		  console.log('fetchExistingTodos with query: ' + query.sql);
		  console.log(query);
		  console.log(result)
		  console.table(result);
		  
		  return result.rows;
		} catch (error) {
		  console.error('Error fetching existing todos:', error);
		  throw error;
		}
	  }

	  async saveUserSessionData(sessionData, user_id, session_hash) {
	  
		const statement = {
		  sql: `INSERT INTO user_session (user_id, session_hash, session_data, added_at)
				 VALUES (?, ?, ?, ?)`,
		  args: [user_id, session_hash, JSON.stringify(sessionData), Date.now()]
		};
	  
		let db = await turso.batch([statement], 'write');
		return db;
	  }

	  async saveUserInteractionData(interactionJson, user_id, session_hash) {
		const statements = interactionJson.interactions.map((interaction) => {
		  const interactionHash = hashCode(JSON.stringify(interaction)); // Calculate hash
	  
		  return {
			sql: `INSERT INTO user_interaction (user_id, session_hash, interaction_hash, interaction_data, added_at)
				  VALUES (?, ?, ?, ?, ?)`,
			args: [user_id, session_hash, interactionHash, JSON.stringify(interaction), Date.now()]
		  };
		});
	  
		// Assuming `turso` is your database library with a `batch` function
		let db = await turso.batch(statements, 'write');
		return db;
	  }

	  async saveHashes(hashes, userId) {
		const existingHashIds = new Set();
		const updateStatements = [];
		const insertStatements = [];
	  
		// Fetch existing hashes in a single query
		const existingHashes = await this.fetchExistingHashes(hashes.map(h => h.keyId), userId);
	  
		hashes.forEach(hash => {
		  const existingHash = existingHashes.find(h => h.key_id === hash.keyId);
	  
		  if (existingHash) {
			updateStatements.push({
			  sql: `
				UPDATE hash_lookup
				SET v_or_hash = ?
				WHERE key_id = ? AND user_id = ?
			  `,
			  args: [hash.value, hash.keyId, userId]
			});
		  } else {
			insertStatements.push({
			  sql: `
				INSERT INTO hash_lookup (key_id, v_or_hash, user_id, category)
				VALUES (?, ?, ?, ?)
			  `,
			  args: [hash.keyId, hash.value, userId, 'your_category']
			});
			existingHashIds.add(hash.keyId);
		  }
		});
	  
		// Execute updates and inserts in separate batches
		if (updateStatements.length > 0) {
		  await turso.batch(updateStatements, 'write');
		}
	  
		if (insertStatements.length > 0) {
		  await turso.batch(insertStatements, 'write');
		}
	  }
	  
	  async fetchExistingHashes(keyIds, userId) {
		const query = {
		  sql: `
			SELECT key_id, v_or_hash
			FROM hash_lookup
			WHERE key_id IN (:keyIds) AND user_id = :userId
		  `,
		  args: {
			keyIds: keyIds,
			userId: userId
		  }
		};
	  
		try {
		  const result = await turso.execute(query);
		  return result.rows;
		} catch (error) {
		  console.error('Error fetching existing hashes:', error);
		  throw error;
		}
	  }

	// async updateTodos(todos) {
	// 	const statements = todos.map((todo) => ({
	// 		sql: `
	// 		  UPDATE todo
	// 		  SET description = ?,
	// 			  updated_at = ?,
	// 			  completed_at = ?,
	// 			  completed = ?,
	// 			  device_id = ?,
	// 			  location_id = ?,
	// 			  active = ?,
	// 			  list_name = ?,
	// 			  session_id = ?
	// 		  WHERE todo_id = ? AND user_id = ? AND list_id = ?
	// 		`,
	// 		args: [
	// 			todo.description,
	// 			todo.updated_at,
	// 			todo.completed_at,
	// 			todo.completed,
	// 			JSON.stringify(todo.device_id),
	// 			JSON.stringify(todo.location_id),
	// 			todo.active,
	// 			todo.list_name,
	// 			todo.session_id,
	// 			todo.todo_id,
	// 			todo.user_id,
	// 			todo.list_id,
	// 		],
	// 	}));



	// 	try {
	// 		await turso.batch(statements, 'write');
	// 		console.log('Todos updated successfully');
	// 		// Handle success, e.g., show a success message
	// 	} catch (error) {
	// 		console.error('Error updating todos:', error);
	// 		// Handle error, e.g., show an error message
	// 		return error
	// 	}
	// }

	// BELOW code works
	// async saveTodos(todos) {
	// 	const statements = todos.map((todo) => ({
	// 		sql: `
	// 		INSERT INTO todo (todo_id, list_id, list_name, description, added_at, updated_at, completed_at, completed, user_id, session_id, device_id, location_id, active)
	// 		VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
	// 	  `,
	// 		args: [
	// 			todo.todo_id,
	// 			todo.list_id,
	// 			todo.list_name,
	// 			todo.description,
	// 			todo.added_at,
	// 			todo.updated_at,
	// 			todo.completed_at,
	// 			todo.completed,
	// 			todo.user_id,
	// 			todo.session_id,
	// 			JSON.stringify(todo.device_id),
	// 			JSON.stringify(todo.location_id),
	// 			todo.active,
	// 		],
	// 	}));
	// 	console.log(`in saveTodos with sql-batch: ${statements} and todos= ${todos}`)
	// 	console.log('statements:')
	// 	console.log(statements)

	// 	try {
	// 		await turso.batch(statements, 'write');
	// 		console.log('Todos saved successfully');
	// 		// Handle success, e.g., show a success message
	// 	} catch (error) {
	// 		console.error('Error saving todos:', error);
	// 		// Handle error, e.g., show an error message
	// 		return error
	// 	}
	// }


	addTimeCardEntry(timeCardData) {
		if (!firebase.isLoggedIn) {
			return alert('Not authorized')
		}


		this.turso_save_time_card(timeCardData).catch(console.log);
	}

	addActivities(activityData) {
		if (!firebase.isLoggedIn) {
			return alert('Not authorized')
		}

		const temp = [];
		let device_info = activityData.deviceInfo;
		let user_id = this.getCurrentAppUserInfo();

		activityData.completed.map((e) => {
			e.utc_saved = Date.now()
			e.user_id = user_id
			e.device_info = device_info
			temp.push(e)
		});

		this.turso_save_activities(temp);
	}

	getCurrentAppUserInfo() {
		return firebase.getCurrentUserIdAndEmail();
	}

	getCurrentAppUserInfoHash() {
		return hashCode(this.getCurrentAppUserInfo());
	}

}

export default new Sqlitedb()