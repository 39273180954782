import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import {
  Alert,
  IconButton,
  Stack,
  Snackbar,
  AlertTitle,
  TextField,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey =  process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

const TodoNotification = ({ notification, markAsRead, addComment }) => {
  const [comment, setComment] = useState('');

  const handleCommentSubmit = async () => {
    const { data, error } = await supabase
      .from('notifications')
      .update({ comments: [...notification.comments, comment] })
      .eq('id', notification.id);

    if (error) {
      console.error('Error adding comment:', error);
    } else {
      setComment('');
    }
  };

  return (
    <Alert
      severity="info"
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={() => markAsRead(notification.id)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <AlertTitle>{notification.title}</AlertTitle>
      {notification.message}
      <div>
        {notification.comments?.map((comment, index) => (
          <div key={index}>{comment}</div>
        ))}
      </div>
      <TextField
        label="Add Comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleCommentSubmit();
          }
        }}
      />
      <Button onClick={handleCommentSubmit}>Add Comment</Button>
    </Alert>
  );
};

export default function TodoNotifications() {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchNotifications = async () => {
    const { data, error } = await supabase
      .from('notifications')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching notifications:', error);
    } else {
      setNotifications(data);
      setUnreadCount(data.filter((n) => !n.read).length);
    }
  };

  const markAsRead = async (id) => {
    const { data, error } = await supabase
      .from('notifications')
      .update({ read: true })
      .eq('id', id);

    if (error) {
      console.error('Error marking notification as read:', error);
    } else {
      fetchNotifications();
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div>
      <Snackbar open={notifications.length > 0}>
        <Alert severity="info">
          You have {unreadCount} unread notifications.
        </Alert>
      </Snackbar>
      <div>
        {notifications.map((notification) => (
          <TodoNotification
            key={notification.id}
            notification={notification}
            markAsRead={markAsRead}
          />
        ))}
      </div>
    </div>
  );
}