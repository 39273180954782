import React, { useEffect, useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Typography, Paper, Avatar, CircularProgress, Button, CssBaseline } from '@mui/material';
import VerifiedUserOutlined from '@mui/icons-material/VerifiedUserOutlined';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../withRouter';
import firebase from '../firebase';
import { PrimarySearchAppBar, StickyFooter } from '../Layouts';
import Map from '../Map';
import Notifications from '../Notifications';
import StoreProvider from '../Hooks/store';

const Main = styled('main')(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // Full viewport height
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  maxWidth: 600,
  [theme.breakpoints.up(400 + theme.spacing(3))]: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

function Dashboard(props) {
  const navigate = useNavigate();
  const { classes } = props;

  useEffect(() => {
    if (!firebase.getCurrentUsername()) {
      // not logged in
      alert('Please login first');
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  return (
    <StoreProvider>
      <CssBaseline />
      <PrimarySearchAppBar />
      <Notifications />
      <Map />
      <StickyFooter />
    </StoreProvider>
  );

  async function logout() {
    await firebase.logout();
    navigate('/', { replace: true });
  }
}

export default withRouter(Dashboard);
