import React, { useEffect } from 'react';
import { notify } from "../Notifications";

import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Badge, IconButton, Menu, MenuItem, Typography, Toolbar } from "@mui/material";
import MoreIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import firebase from '../firebase';
import useFirebaseAuthListener from '../Hooks/useFirebaseAuthListener';

import { useNavigate } from 'react-router-dom';
import { withRouter } from '../withRouter';
import TimeCard from '../TimeCard';
import Homework from '../Homework';
import TodoList from '../TodoList';
import Workout from '../Workout';
import TodoNotifications from '../Notifications/TodoNotifications';
import { StoreContext } from '../Hooks/store';
import { StatusIcon } from '../Shared/StatusIcon';
import { SimpleAppBarStyles } from '../Styling/SimpleAppBarStyles';
import { getDeviceInfo } from '../Utils/getDeviceInfo';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';
import { hashCode } from '../Utils/hashCode';
import sqlitedb  from '../sqlitedb';

function PrimarySearchAppBar(props) {
  const classes = SimpleAppBarStyles();
  const {
    timecard,
    thisdeviceid,
    thissessionid,
    thisuserid,
    thislocationid,
    modaldialog,
    mysessiondata,
    mytodolistnames,
    myinteractions
  } = React.useContext(StoreContext);
  const [interactiondata] = myinteractions;
  const [statuscolorTimecard] = timecard;
  const [deviceid, setDeviceid] = thisdeviceid;
  const [sessionid, setSessionid] = thissessionid;
  const [userid, setUserid] = thisuserid;
  const [locationid, setLocationid] = thislocationid;
  const [sessiondata, setsessiondata] = mysessiondata;
  const [kvtodolistnames, setKvtodolistnames] = mytodolistnames;

  // const { modaldialog } = React.useContext(StoreContext);
  const [loadedmodal, setLoadedmodal] = modaldialog;

  const prps = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = 'primary-search-account-menu';

  const navigate = useNavigate()
  const { clearSessionData, trackInteraction } = useUserInteractionTracker();

  const saveSessionDataToDatabase = () => {
    const updatedSessionData = {
      ...sessiondata,
      end_at: Date.now() // Update end_at to the current timestamp
    };

    //sessiondata(updatedSessionData)

    const { dvc_hash, dvc_json } = updatedSessionData.device;
    console.log('to save from session')

    console.log('dvc_hash')
    console.log(dvc_hash)
    console.log('dvc_json')
    console.log(dvc_json)

    saveSessionToDb(updatedSessionData);
    saveUserInteractionToDb()
  }

  async function saveSessionToDb(sessionJson) {
    trackInteraction('form_submit', { formName: 'Save.Sessiondata' });
    try {
      console.dir(sessionJson, {depth: null});
      notify('Saving...')
      await sqlitedb.saveUserSessionData(sessionJson, userid, sessionid)
      //notify('Save Completed.')
    } catch (error) {
      notify('Error saving user interractions.')
      console.log('saveUserInteractions reported error below...');
      console.dir(sessionJson, {depth: null})
      console.log(error)
    }
  }

  async function saveUserInteractionToDb() {
    // const interactionHash = hashCode(JSON.stringify(interaction)); // Calculate hash

    // // Prepare data for database insertion
    // const dataToInsert = {
    //   key: interactionHash,
    //   value: JSON.stringify(interaction)
    // };
    trackInteraction('form_submit', { formName: 'Save.Interactions' });
    try {
      console.log(interactiondata);
      notify('Saving...')
      await sqlitedb.saveUserInteractionData(interactiondata, userid, sessionid)
      //notify('Save Completed.')
    } catch (error) {
      notify('Error saving user interractions.')
      console.log('saveUserInteractions reported error below...');
      console.dir(interactiondata, {depth: null})
      console.log(error)
    }
  }


  const handleLogin = () => {
    //console.clear()
    console.log('handleLogin');
    // Clear session data here

    initializeState('handleLogin');

    trackInteraction('login', {
      sessionHash: sessionid, //newSessionHash,
      utcTime: new Date().toUTCString(),
      localTime: new Date().toLocaleTimeString(),
    }
    );
  };

  const handleLogout = () => {
    // // Save session data to database should have been called and completed by the time we get here!
    clearSessionData();
  };

  useFirebaseAuthListener(handleLogin, handleLogout);


  useEffect(() => {
    clearSessionData();

    initializeState('useEffect');
    trackInteraction('page_view', { sessionHash: sessionid, pageName: 'HomePage' });

    //notify('Geolocating....')
    //handleLocation()
  },
    []
  );

  const initializeState = (calledBy = '') => {
    const rtnow = Date.now();
    console.log(`in initializeState-- caller ${calledBy}: rtnow=${rtnow}`);


    //const sessionObj = hashCode(firebase.getCurrentUserEmail()) + '_' + rtnow;
    //setSessionid(sessionObj)

    setUserid(hashCode(firebase.getCurrentUserEmail()));

    const obj = JSON.stringify(getDeviceInfo());
    const dvc_obj = {
      dvc_hash: hashCode(obj),
      dvc_json: obj,
    };
    setDeviceid(dvc_obj);

    if (!sessionid || sessionid === '') {
      const newSessionHash = hashCode([sessionid, userid, deviceid, Date.now()].join(","));
      setSessionid(newSessionHash);

      const session_obj = {
        session_hash: newSessionHash,
        //session: newSessionHash, //sessionObj,
        start_location: {},
        device: dvc_obj,
        start_at: rtnow,
        end_at: rtnow,
        //interactions: [],
        session_locations: []
      };

      console.dir(session_obj, { depth: null });
      setsessiondata(session_obj);
      console.log(session_obj);
      console.log('above should match below');
      console.log(sessiondata);
      //return newSessionHash;
    }
    else{
      console.log('session already set: ', sessionid)
    }
  }

  function handleLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(saveCurrentLocation);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function saveCurrentLocation(position) {
    console.log(`in saveCurrentLocation with ${position}`);

    // https://googleapis.dev/nodejs/firestore/latest/GeoPoint.html

    const location_payload = `{
        "lat": ${position.coords.latitude}, 
        "lon": ${position.coords.longitude},
        "now": ${Date.now()}
    }`;


    const locObj = JSON.stringify(JSON.parse(location_payload));
    let obj = {
      loc_hash: hashCode(locObj),
      loc_json: locObj,
    };
    setLocationid(obj);

    console.dir(locObj, { depth: null })
    console.dir(locationid, { depth: null })

    // Update session data with new values
    setsessiondata((prev) => ({
      ...prev,
      start_location: locObj,
      session_locations: locObj,
    }));

  }

  // function updateSessionDataWithLocation(newLocationObj) {
  //   const updatedSessionData = {
  //     ...sessiondata,
  //     //session_hash: calculateNewSessionHash(newLocation), // Recalculate session hash
  //     //end_at: Date.now(), // Update end_at timestamp
  //     session_locations: [...sessiondata.other_locations, newLocationObj] // Add new location to the array
  //   };

  //   setsessiondata(updatedSessionData);
  // }

  function calculateNewSessionHash() {
    // Assuming you have deviceid, sessionid, and userid available
    return hashCode([deviceid, sessionid, userid, Date.now(), locationid].join(","));
  }

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomMenuOpen = () => {
    handleMenuClose();
  };

  const handleTimecardMenuOpen = () => {
    setLoadedmodal('Timecard');
    handleMenuClose();
  };

  const handleHomeworkMenuOpen = () => {
    setLoadedmodal('Homework');
    handleMenuClose();
  };

  const handleTodoMenuOpen = () => {
    setLoadedmodal('Todolist');
    handleMenuClose();
  };

  const handleTodoNotesMenuOpen = () => {
    setLoadedmodal('Todonotes');
    handleMenuClose();
  };

  const handleWorkoutMenuOpen = () => {
    setLoadedmodal('Workout');
    handleMenuClose();
  };



  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const doLogout = () => {
    // Save session data to database here
    saveSessionDataToDatabase();

    logout(prps);
    handleMenuClose();
  };




  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={doLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleTodoMenuOpen}>
        <IconButton onClick={handleTodoMenuOpen} aria-label="show 4 new mails" color="inherit" size="large">
          <Badge badgeContent={42} color="secondary">
            <MailIcon onClick={handleTodoMenuOpen} />
          </Badge>
        </IconButton>
        {/* <p>Todo Items</p> */}
        <TodoList rendercolor='primary' />
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit" size="large">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon onClick={handleTodoNotesMenuOpen} />
          </Badge>
        </IconButton>
        {/* <TodoNotifications /> */}
      </MenuItem>
      <MenuItem onClick={handleTimecardMenuOpen}>
        <TimeCard rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleHomeworkMenuOpen}>
        <Homework rendercolor='primary' />
      </MenuItem>
      <MenuItem onClick={handleWorkoutMenuOpen}>
        <Workout rendercolor='primary' />
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  async function logout(props) {
    console.log('logging out. TODO: Save session to db then clear it')
    //setSessionid('')

    trackInteraction('login', {
      utcTime: new Date().toUTCString(),
      localTime: new Date().toLocaleTimeString()
    });

    await firebase.logout()
    //props.history.push('/login')
    navigate('/login', { replace: true });
  }


  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.customizeToolbar} >
          <Typography className={classes.title} variant="h6" noWrap>
            geowork.app
          </Typography>
          <span className={classes.statusIconSpan}>
            <StatusIcon cls1={statuscolorTimecard} />
          </span>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="Todos"
              onClick={handleCustomMenuOpen}
              color="inherit"
              size="large">
              <Badge badgeContent={44} color="secondary">
                <MailIcon onClick={handleTodoMenuOpen} />
              </Badge>
              <TodoList rendercolor='#fff' />
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit" size="large">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon onClick={handleTodoNotesMenuOpen} />
              </Badge>
              {/* <TodoNotifications /> */}
            </IconButton>
            <IconButton
              aria-label="Time Card"
              onClick={handleCustomMenuOpen}
              color="inherit"
              size="large">
              <TimeCard rendercolor='#fff' />
            </IconButton>
            <IconButton
              aria-label="Homework"
              onClick={handleCustomMenuOpen}
              color="inherit"
              size="large">
              <Homework rendercolor='#fff' />
            </IconButton>
            <IconButton
              aria-label="Workout"
              onClick={handleCustomMenuOpen}
              color="inherit"
              size="large">
              <Workout rendercolor='#fff' />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large">
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

export default withRouter(PrimarySearchAppBar);
