import React, { useEffect } from 'react';
import { Btn, StartBtn, PauseBtn, TimerLbl, InputText, Select } from './ActivityTimer.styled';

import { StoreContext } from '../Hooks/store';
import useStickyState from '../Hooks/useStickyState';

import { hashCode } from '../Utils/hashCode';
import { localTime } from '../Utils/localTime';
import { prettyTime } from '../Utils/prettyTime';

import firebase from '../firebase'
import sqlitedb  from '../sqlitedb';
import { notify } from "../Notifications";
import { getDeviceInfo } from '../Utils/getDeviceInfo';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';


export default function ActivityTimer(props) {
  const { trackInteraction } = useUserInteractionTracker();
  const [customtxt, setCustomtxt] = useStickyState('', 'custom' + hashCode('custom' + props.activity));
  const [selectedval, setSelectedval] = useStickyState('', 'selectedval' + hashCode('selectedval' + props.activity));

  //const selectOptions = props.options;
  const selectOptions = Array.isArray(props.options) ? props.options : [];


  const [time, setTime] = useStickyState(new Date().toLocaleTimeString(), 'time' + hashCode('time' + props.activity));
  const [paused, setPaused] = useStickyState(false, 'paused' + hashCode('paused' + props.activity));
  const [stopped, setStopped] = useStickyState(true, 'stopped' + hashCode('stopped' + props.activity));
  const [elapsed, setElapsed] = useStickyState(0, 'elapsed' + hashCode('elapsed' + props.activity));
  const [label, setLabel] = useStickyState('Start', 'label' + hashCode('label' + props.activity));
  const [elapsedlabel, setElapsedlabel] = useStickyState('00:00:00', 'elapsedlabel' + hashCode('elapsedlabel' + props.activity));
  const [tm1, setTm1] = useStickyState(Date.now(), 'tm1' + hashCode('tm1' + props.activity));
  const [carry, setCarry] = useStickyState(0, 'carry' + hashCode('carry' + props.activity));
  const [pauselabel, setPauselabel] = useStickyState('Pause', 'pauselabel' + hashCode('pauselabel' + props.activity));
  const [pausedisabled, setPausedisabled] = useStickyState(true, 'pausedisabled' + hashCode('pausedisabled' + props.activity));
  //const [sessionid] = useStickyState(hashCode(firebase.getCurrentUserEmail() + '_' + Date.now()), 'sessionid' + hashCode(firebase.getCurrentUserEmail() + '_' + Date.now()));
  
  const {
    activitiescustom,
    activitiesselected,
    activitiessaved,
    activitiesclockedin,
    thissessionid,
    activitiesaddvisible,
    thisdeviceid,
   } = React.useContext(StoreContext);
  const [donecustom, setDonecustom] = activitiescustom;
  const [doneselect, setDoneselect] = activitiesselected;
  const [savedactivities, setsavedactivities] = activitiessaved; 
  const [clockedin, setClockedin] = activitiesclockedin;
  const [sessionid, setSessionid] = thissessionid;
  const [deviceid, setDeviceid] = thisdeviceid;
  const [showaddactivity, setShowaddactivity] = activitiesaddvisible;
  //const [showAddChoice, setShowAddChoice] = useState(false)
  const { clockTimer, selectTimer, customTimer } = determineTimer();




  function determineTimer() {
    const clockTimer = props.activity !== 'Custom' && props.activity !== 'Select';
    const selectTimer = props.activity === 'Select' && selectOptions;
    const customTimer = props.activity === 'Custom';
    return { clockTimer, selectTimer, customTimer };
  }

  function formatElapsed(totalSecs) {
    //return new Date(totalSecs * 1000).toISOString().substr(11, 8);
    return prettyTime(totalSecs * 1000);
  }

  function handleStartStop() {
    if (label === 'Start') {
      // we are starting
      if (clockTimer) {
        trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Start' });
        setClockedin(true)
        setSessionid(hashCode(firebase.getCurrentUserEmail()) + '_' + Date.now())
        
        //notify(sessionid)
      }
      setCarry(0);
      setTm1(Date.now())
      setLabel('Stop')
      setStopped(false)
      setPaused(false)
      setPausedisabled(false)
      //sqlitedb.get_turso_test();
    } else {
      // we are stopping
      trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Stop' });
      const startedAt = tm1;
      const rightnow = Date.now();
      setCustomtxt('');
      setLabel('Start')
      setStopped(true)
      setPaused(false)
      setPausedisabled(true)
      setPauselabel('Pause')
      setTm1(rightnow)
      setElapsedlabel('00:00:00')

      if (props.activity === 'Custom') {
        saveToStickyState(customtxt, rightnow);
        //createFoo({ variables: { f: customtxt, e: elapsed } });
      }

      if (props.activity === 'Select') {
        saveToStickyState(selectedval, rightnow);
        setSelectedval('');
      }


      if (clockTimer) {
        setClockedin(false)
        //setSessionid('')

        const clockoutItem = makeTempArray('clockout', elapsedlabel, elapsed, tm1, rightnow);
        const completedItems = [...clockoutItem, ...donecustom, ...doneselect];
        const doneCount = completedItems.length;
        const activity_json = {
          count: doneCount,
          completed: completedItems,
          deviceInfo: deviceid ? deviceid : hashCode(JSON.stringify(getDeviceInfo())),
        }
        saveActivitiesToFirebase(activity_json);
        getSavedActivities(); // to force UI refreh. TODO - figure out a better way instead of db call
      }
    }
  }


  function saveToStickyState(doneAct, rightnow) {
    const temp = makeTempArray(doneAct, elapsedlabel, elapsed, tm1, rightnow);

    if (props.activity === 'Custom') {
      setDonecustom([...donecustom, ...temp]);
    }

    if (props.activity === 'Select') {
      setDoneselect([...doneselect, ...temp]);
    }
  }


  useEffect(() => {
    notify('Loading....')
    getSavedActivities()
}, []);

async function getSavedActivities() {
    try {
      notify('Refreshing....')
        return await sqlitedb.getActivitiesForLast5days().then(x => {
            const temp = [];
            let latest_mod_eventname = '';
            let latest_saved = 0;
            let latest_mod_utc_end = 0;
            let latest_saved_day = 0;
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

            Object.entries(x).map(([, v]) => {

                if (v.utc_saved > latest_saved) { // prep to calculate newest event
                    latest_saved = v.utc_saved;
                    latest_mod_eventname = v.activity;
                    latest_mod_utc_end = v.utc_end;
                }

                //let current_seen_date = new Date(v.utc_saved).toLocaleDateString();
                let current_seen_day = new Date(v.utc_saved).setHours(24, 0, 0, 0);
                //console.log(current_seen_day);

                if (current_seen_day > latest_saved_day) {
                    latest_saved_day = current_seen_day;

                    // add a date separator for the UI
                    let SeparatorObj = {
                        utc_start: '',
                        utc_end: localTime(v.utc_end),
                        activity: new Date(v.utc_saved).toLocaleDateString('en-us', options),
                        local_start: v.local_start,
                        local_end: v.local_end,
                        elapsedlabel: v.elapsedlabel,
                        elapsed: v.elapsed,
                        utc_saved: v.utc_saved
                    };
                    temp.push(SeparatorObj);
                }

                // https://youtu.be/RZ5wKYbOM_I
                let newObj = {
                    utc_start: localTime(v.utc_start),
                    utc_end: localTime(v.utc_end),
                    activity: v.activity, //`${v.activity} (${v.elapsed_label})`,
                    local_start: v.local_start,
                    local_end: v.local_end,
                    elapsedlabel: v.elapsedlabel,
                    elapsed: v.elapsed,
                    utc_saved: v.utc_saved,
                };
                temp.push(newObj);

            })

            temp.sort((a, b) => b.utc_saved - a.utc_saved); // sort descending
            setsavedactivities(temp)
        })
    } catch (error) {
        console.log(error)
        notify(error.message)
    }
}

  async function saveActivitiesToFirebase(activity_json) {
    trackInteraction('form_submit', { sessionHash: sessionid, formName: 'Save.Activities' });
    try {
      console.log(activity_json);
      notify('Saving...')
      //await firebase.addActivities(activity_json);
      await sqlitedb.addActivities(activity_json);
      setDoneselect([]) // clear local storage
      setDonecustom([]) // clear local storage
      notify('Save Completed.')
    } catch (error) {
      notify('Error saving activities.')
      console.log('addActivities reported error below... localstorage should still have completed activities; save to cloud db failed.');
      console.log(error)
    }
  }

  function handlePauseResume() {
    if (pauselabel === 'Pause') {
      trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Resume' });
      const sofar = carry + (Date.now() - tm1) / 1000
      setCarry(sofar)
      setPaused(true)
      setStopped(false)
      setPauselabel('Resume')
    } else {
      trackInteraction('button_click', { sessionHash: sessionid, buttonText: 'Activity.Pause' });
      setPaused(false)
      setPauselabel('Pause')
      setTm1(Date.now())
    }
  }

  function handleOnChange(e) {
    setCustomtxt(e.target.value);
  }

  function handleOnSelectedChange(e) {
    setSelectedval(e.target.value);
  }

  function toggleAddActivity() {
    setShowaddactivity(!showaddactivity);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {

      const date = new Date()
      setTime(date.toLocaleTimeString());
      if (paused) {
        return;
      }

      if (stopped) {
        return;
      }

      const diff1 = carry + (Date.now() - tm1) / 1000;

      setElapsed(diff1);
      setElapsedlabel(formatElapsed(elapsed));
    }, 1000);
    return () => {
      clearTimeout(timeout);
    }
  }, [time]);


  return (
    <>
      {clockTimer &&
        <>
          <TimerLbl inputColor={props.color}>{props.activity}</TimerLbl>
          <StartBtn inputColor={props.color} onClick={() => handleStartStop()}>{label}</StartBtn>
        </>
      }
      {selectTimer &&
        <>
          <label for="activitydropdown">{props.placeholder}</label><br />
          <Select id="activitydropdown" name="activitydropdown" disabled={!clockedin || label === 'Stop'} onChange={handleOnSelectedChange} value={selectedval}>
            {selectOptions.map((opt) => (
              <option value={opt}>{opt}</option>
            ))}
          </Select>
          {!showaddactivity && <Btn
            disabled={!clockedin || label === 'Stop'}
            inputColor={'green'}
            onClick={() => toggleAddActivity()}>+</Btn>
          }
          <StartBtn disabled={!clockedin || selectedval === undefined || selectedval === '' || selectedval === ""}
            inputColor={props.color}
            onClick={() => handleStartStop()}>{label}
          </StartBtn>
        </>
      }
      {customTimer &&
        <>
          <label for="custombox">One-time activity:</label><br />
          <InputText
            name="custombox"
            id="custombox"
            maxLength="80"
            disabled={!clockedin || label === 'Stop'}
            value={customtxt === props.placeholder ? '' : customtxt}
            onChange={handleOnChange}
            // placeholder={props.placeholder}
            onMouseEnter={() => {
              if (customtxt === 'Please fill' || customtxt === "Please fill") {
                setCustomtxt('');
              }
            }
            }
          ></InputText>
          <StartBtn
            disabled={
              !clockedin
              || customtxt === undefined
              || customtxt === ''
              || customtxt === ""
              || customtxt === props.placeholder
              || customtxt === 'Please fill'
              || customtxt === "Please fill"}
            inputColor={props.color}
            onClick={() => handleStartStop()}>{label}
          </StartBtn>
        </>
      }

      {label === 'Stop' &&
        <>
          <PauseBtn inputColor={props.color} onClick={() => handlePauseResume()} disabled={!clockedin || pausedisabled}>{pauselabel}</PauseBtn>
          <TimerLbl inputColor={props.color}>{elapsedlabel}</TimerLbl>
        </>
      }
    </>
  )


}

function makeTempArray(doneAct, elapsedlabel, elapsed, tm1, rightnow) {
  const temp = [];
  const newObj = {
    activity: doneAct,
    elapsedlabel: elapsedlabel,
    elapsed: elapsed,
    utc_start: tm1,
    utc_end: rightnow,
    local_start: localTime(tm1),
    local_end: localTime(rightnow),
  };
  temp.push(newObj);
  return temp;
}