import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import makeStyles from '@mui/styles/makeStyles';

//import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import { StoreContext } from '../Hooks/store';

import { notify } from "../Notifications";
import Todos from './Todos';
import ArchivedTodolists from './ArchivedTodolists';

import sqlitedb from '../sqlitedb';

import { v4 as uuidv4 } from "uuid";
import { hashCode } from '../Utils/hashCode';
import useUserInteractionTracker from '../Hooks/useUserInteractionTracker';

const useStyles = makeStyles((theme) => ({
    iconwidth: {
        minWidth: '35px !important',
    },
    menuwidth: {
        width: 'inherit'
    },

    menuDiv: { marginLeft: '10px' },
    activitiesMenu: {
        fontSize: '12pt',
        verticalAlign: 'middle',
        marginBottom: '5px',
        display: 'inline-block'
    },

    tctitle: {
        fontFamily: 'Montserrat', fontSize: '11pt',
        backgroundColor: '#00416e', color: '#fff',
        textAlign: 'center'
    },
    b1: {
        backgroundColor: '#fff',
        minWidth: '320px',
        marginLeft: '-5px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function TodoList(props) {

    const {
        modaldialog,
        todolistheader,
        todolistheaderid,
        todolistsaved,
        thisuserid,
        showarchiveflag,
        tododirtycheck,
        thisdeviceid,
        thislocationid,
        thissessionid,
    } = React.useContext(StoreContext);

    const { trackInteraction } = useUserInteractionTracker();

    const [, setLoadedmodal] = modaldialog;
    const [dirtytodos, setdirtytodos] = tododirtycheck;
    const [todoHeader, setTodoHeader] = todolistheader;
    const [todolistid, settodolistid] = todolistheaderid;
    const [todos, setTodos] = todolistsaved;
    const [userid, setUserid] = thisuserid;
    const [showarchived, setShowarchived] = showarchiveflag;
    const [deviceid] = thisdeviceid;
    const [locationid] = thislocationid;
    const [sessionid, setSessionid] = thissessionid;

    const [menucolor] = useState(props.rendercolor)



    //const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const classes = useStyles();

    //let workouticon = menucolor === 'primary' ? <WatchOutlinedIcon color="primary" /> : <WatchOutlinedIcon style={{ color: menucolor }} />

    const [open, setOpen] = useState(false);

    function handleOnClose() {
        saveTodolist(todos)
        setLoadedmodal("")
        setOpen(false)
        trackInteraction('button_click', { buttonText: 'Todolist.close' });
    }

    const getTodolistHeaderId = () => {
        if (!todolistid || todolistid === '') {
            let todoListHeaderId = hashCode(uuidv4());
            console.log(`getTodolistHeaderId.todoListHeaderId=${todoListHeaderId}`);
            settodolistid(todoListHeaderId); // note that this updates todolistid
        }
    }

    const assignPropsMissingInTodos = todos => {
        getTodolistHeaderId();
        console.log(`in assignListIdIfMissing. todolistid= ${todolistid}. todos= ${todos} `)
        console.log('todolistid')
        console.log(todolistid)
        console.log('todos')
        console.log(todos)

        return Array.isArray(todos) ? todos.map(todo => ({
            ...todo,
            list_id: todo.list_id ?? todolistid,
            aadded_at: todo.aadded_at ?? Date.now(),
            updated_at: todo.updated_at ?? Date.now(),
            completed_at: todo.completed_at ?? null,
            device_id: todo.device_id ?? deviceid.dvc_hash,
            location_id: todo.location_id ?? locationid.loc_hash,
            session_id: todo.session_id ?? sessionid,
            active: todo.active ?? 1,
            user_id: todo.user_id ?? userid,
        })) : [];
    };


    async function saveTodolist(todoObj) {
        trackInteraction('form_submit', { formName: 'Todolist' });
        // if (!todoObj || todoObj.length < 1) {
        //     notify('To-do list empty. Nothing to save')
        //     return;
        // }

        const isDirty = dirtytodos !== hashCode(JSON.stringify(todoObj) + todoHeader + todolistid);
        if (!isDirty) {
            console.log('No changes to save');
            return;
          } else {
            notify('Saving...')
          }

        try {
            todoObj = assignPropsMissingInTodos(todoObj);
            console.log(todoObj)
            //await sqlitedb.saveTodos(todoObj);
            await sqlitedb.saveTodos(todoObj, userid, todolistid)
            setdirtytodos(hashCode(JSON.stringify(todos) + todoHeader + todolistid));
            notify('To-do list saved!')
        } catch (error) {
            notify('Error saving to-do list to remote server')
            console.log('saveTodolist reported error below...');
            console.log(error)
        }
    }

    function handleOnOpen() {
        notify('Coming soon... get data from remote store. Merge it with local cache over-writing remote dups with local updates.')
        setLoadedmodal("Todolist")
        setOpen(true)
        trackInteraction('page_view', { pageName: 'Todolist' });
    }

    const toggleShowArchiveFlag = () => { setShowarchived(!showarchived); };

    return (<>
        <div className={classes.menuDiv} onClick={handleOnOpen}>
            {/* {workouticon} */}
            <span className={classes.activitiesMenu}>&nbsp;&nbsp;Todos</span>
        </div>
        <Dialog open={open} onClose={handleOnClose}>
            <DialogTitle className={classes.tctitle} onClose={handleOnClose} disableTypography>
                <MenuOutlinedIcon
                    onClick={toggleShowArchiveFlag}
                    style={{ position: 'absolute', left: '15px', marginRight: '8px' }}
                />
                {showarchived ? 'All Lists' : todoHeader}
                {/* <CurrentDate options={dateOptions} /> */}
                {/* {todoHeader} */}

                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleOnClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.b1}>
            {showarchived ? <ArchivedTodolists /> : <Todos />}
            </DialogContent>
            

        </Dialog>
    </>);
}

